<style>
    .user-search-field .v-input__append-outer {
        margin-top:0px !important;
    }
</style>

<template>
    <div>
        <v-text-field filled label="Enter search criteria" v-model="searchText" class="user-search-field" hide-details>
            <template v-slot:append-outer>
                <v-btn style="height:56px;" @click="search">Search</v-btn>
            </template>
        </v-text-field>
        <v-data-table v-model="selected" height="20vh" style="width:100%;" :headers="headers" :loading="loading" :items="users" class="elevation-2 mt-4" show-select single-select item-key="_id">
        <template v-slot:item.company="{item}">
            {{lookupCompanyName(item.company)}}
        </template>
        </v-data-table>
    </div>
</template>

<script>
export default {
    props: {
        value: {},
        show: {},
    },
    data: function () {
        return {
            searchText: "",
            users: [],
            headers: [
                { text: "Name", value: "name" },
                { text: "Email", value: "email" },
                { text: "Company", value: "company" },
            ],
            loading: false,
            selected: [],
            companies: [],
        }
    },
    mounted: function () {
        this.listCompanies();
    },
    created: function () {
    },
    computed: {
    },
    watch: {
        selected(val) {
            this.$emit("input", val[0]);
        }
    }, 
    methods: {
        lookupCompanyName(id) {
            var company = this.companies.find(company => company._id == id);
            if (company) {
                return company.name;
            }
            return "Unassigned";
        },  
        async listCompanies() {
            var result = await this.$api.get("company/listNames", this.$user);
            if (result) {
                this.$set(this, "companies", result.data);
            }
        },
        async search() {
            this.loading = true;
            var result = await this.$api.get("user/search?q=*" + this.searchText + "*", this.$user);
            if (result) {
                var items = [];
                for (var record of result.data) {
                    var user = record._source;
                    user._id = record._id;
                    items.push(user);
                }               
                this.$set(this, "users", items);
            }

            this.loading = false;
        },
    },
    components: {
    }
}
</script>