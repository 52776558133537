export default {
    light: {
        primary: "#a41f35",
        secondary: "#333e48",
        accent: "#939caa",
        error: "#ff0000",
        success: "#009966",
        anchor: "#A41F35"
    },
    dark: {
        primary: "#a41f35",
        secondary: "#333e48",
        accent: "#939caa",
        error: "#ff0000",
        success: "#009966",
        anchor: "#A41F35"
    }
}