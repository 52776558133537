<template>
	<v-overlay :value="show">
		<v-card>
			<v-card-title>Access Denied</v-card-title>
			<v-card-text style="text-align:center">
				You do not have access to this function.<br/>If you believe this is a mistake please contact Soldier On admin.<br/>
				<v-btn color="primary" class="mt-2" @click="back">Click to go back</v-btn>
			</v-card-text>
		</v-card>
	</v-overlay>
</template>

<script>
export default {
	props: {
        value: {
            type: Boolean
        }
	},
    data: function () {
        return {
			show: false
		};
    },
	mounted() {
		this.show = this.value;
	},
		
	watch: {
		value(val) {
			this.show = val;
		}
	},
	methods: {
		back() {
			this.$router.go(-1);
		}
	}
}
</script>