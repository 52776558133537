<style>
    .custom-heading {
        color: rgba(0,0,0,0.6);
        background-color: #F0F0F0;
    }

    .EasyMDEContainer .editor-toolbar {
        border-top-left-radius: 0px !important;
        border-top-right-radius: 0px !important;
    }

    .editor-preview-side {
        z-index:7 !important;
    }
</style>

<template>
    <v-container class="elevation-4" fill-height style="align-items:flex-start;position:relative;">
        <v-overlay :value="loading">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-container class="pb-14">
            <v-row>
                <v-col class="pb-0">
                    <h2>Post a Job</h2>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="pb-0">
                    <v-text-field filled label="Job Title" v-model="posting.title" persistent-hint hint="The job title will be shown at the top of the job listing page and as the heading for the search results" counter="100"></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="pb-0">
                    <v-textarea filled label="Job Summary" v-model="posting.summary" persistent-hint hint="The job summary will be shown as the content within the search results" counter="500"></v-textarea>
                </v-col>
            </v-row>            
            <v-row>
                <v-col cols="6" class="pb-0">
                    <v-select filled label="Job Category" v-model="posting.category" persistent-hint hint="Please select a category for this job to be sorted into" :menu-props="{ bottom: true, offsetY: true }" :loading="categories.loading" :items="categories.items" item-text="value" item-value="_id" return-object></v-select>
                </v-col>
                <v-col cols="6" class="pb-0">
                    <v-select filled label="Job Type" v-model="posting.type" persistent-hint hint="The type of job will also be used to match against candidate preferences" :menu-props="{ bottom: true, offsetY: true }" :loading="types.loading" :items="types.items" item-text="value" item-value="_id" return-object></v-select>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6" class="pb-0">
                    <v-autocomplete filled v-model="posting.location" :items="location.items" :loading="location.loading" :search-input.sync="location.search" item-text="display" item-value="id" label="Location" placeholder="Start typing to Search" append-icon="mdi-map-marker" return-object no-filter clearable hide-no-data></v-autocomplete>
                </v-col>
                <v-col cols="6">
                    <v-dialog ref="closedate" v-model="closeDate.dialog" :return-value.sync="posting.closeDate" persistent width="290px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field filled v-model="posting.closeDateDisplay" label="Closing Date" append-icon="mdi-calendar" persistent-hint hint="Date that the job will no longer accept applications from candidates" readonly v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="posting.closeDate" scrollable>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" @click="$refs.closedate.save(posting.closeDate)">OK</v-btn>
                            <v-btn color="secondary" @click="closeDate.dialog = false">Cancel</v-btn>
                        </v-date-picker>
                    </v-dialog>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="pt-0 pb-0">
                    <div class="pa-3 custom-heading rounded-t">
                        <b>Description (<a href="https://www.markdownguide.org/basic-syntax/" target="_blank">in Markdown</a>)</b><br/>
                        The description text editor is supported by Markdown, a tool that helps us format your text for the web. The formatting buttons and your job description can be added as normal. The markdown guideline is attached for support if required. Please direct all further Markdown queries to your Pathways Officer
                    </div>
                    <vue-easymde v-model="posting.descriptionMarkdown" ref="descriptionMarkdown" :configs="{ sideBySideFullscreen: false, previewRender: descriptionPreview, toolbar: toolbar }" @blur="processMarkdown"></vue-easymde>
                    <div class="v-text-field__details pa-2 pl-3">
                        <div class="v-messages theme--light">
                            <div class="v-messages__wrapper">
                                <div class="v-messages__message">Enter a description for the job posting. This is the main body of the job and should be formatted as such.</div>
                            </div>
                        </div>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="7" class="pt-0 pb-0">
                    <v-autocomplete class="mt-4" v-model="posting.requiredSkills" :items="allSkills" clearable small-chips deletable-chips label="Required Skills" persistent-hint hint="Suggesting skills will overwrite existing skills suggestions. You may add more skills after suggesting." multiple outlined menu-props="closeOnContentClick"></v-autocomplete>
                </v-col>
                <v-col cols="5" class="d-flex align-center">
                    <v-btn small block @click="populateRequiredSkills">
                        {{ $vuetify.breakpoint.xsOnly ? "Suggest" : "Suggest from Description" }}
                    </v-btn>
                </v-col>
            </v-row>            
            <v-row>
                <v-col cols="7" class="pt-0 pb-0">
                    <v-autocomplete class="mt-4" message="test" v-model="posting.preferredSkills" :items="allSkills" clearable small-chips deletable-chips label="Preferred Skills" persistent-hint hint="Suggesting skills will overwrite existing skills suggestions. You may add more skills after suggesting." multiple outlined menu-props="closeOnContentClick"></v-autocomplete>
                </v-col>
                <v-col cols="5" class="d-flex align-center">
                    <v-btn small block @click="populatePreferredSkills">
                        {{ $vuetify.breakpoint.xsOnly ? "Suggest" : "Suggest from Description" }}
                    </v-btn>
                </v-col>
            </v-row>       
            <v-row>
                <v-col class="pt-2 pb-2">
                    <v-btn block outlined color="primary" @click="showPreview" width="150" v-if="$vuetify.breakpoint.xsOnly">Preview</v-btn>
                </v-col>
            </v-row>  

        </v-container>        
        <v-dialog persistent v-model="preview.dialog" content-class="elevation-0">
            <v-container>
                <v-card class="elevation-6">
                    <v-card-title>
                        Job Listing Preview
                    </v-card-title>
                    <v-card-text style="max-height:70vh;overflow-y:scroll;">
                        <h3 class="pa-2">Search Result</h3>
                        <job-search-result :item="posting"></job-search-result>
                        <h3 class="pa-2 pt-4">Posting</h3>
                        <job-posting-details :item="posting"></job-posting-details>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer><v-btn color="secondary" @click="preview.dialog = false">Close</v-btn>
                    </v-card-actions>
                </v-card>
            </v-container>
        </v-dialog>
        <div style="position:fixed;bottom:0px;left:0px;width:100%;z-index:10;">
            <v-container class="pa-0">
                <v-card class="pt-4 pb-2 elevation-6">
                    <v-card-actions class="pt-0 d-flex justify-center">
                        <v-btn outlined color="primary" @click="showPreview" width="150" v-if="!$vuetify.breakpoint.xsOnly">
                            Preview
                        </v-btn>
                        <v-btn color="primary" @click="publish" width="150">
                            Publish
                        </v-btn>
                        <v-btn color="secondary" @click="cancel" width="150">
                            Cancel
                        </v-btn>                        
                    </v-card-actions>
                </v-card>
            </v-container>
        </div>
    </v-container>
</template>

<script>
import nlp from "compromise";
import skills from "../skills/skills.json";

import jobSearchResult from "./job.search.result.vue";
import jobPostingDetails from "./job.posting.details.vue";

import dateUtils from "../../util/date.utils";

import events from "../../app/app.events";

import VueEasymde from "vue-easymde";

import showdown from "showdown";

import auth from "../../mixins/auth";

export default {
    mixins: [ auth ],
    props: {
        id: {}
    },
    data: function () {
        return {
            loading: false,
            skills: [],
            allSkills: skills,
            suburbs: [],
            closeDate: {
                dialog: false
            },
            location: {
                loading: false,
                items: [],
                search: ""
            },
            posting: {
                title: "",
                summary: "",
                category: null,
                type: null,
                company: null,
                description: "",
                descriptionText: "",
                descriptionMarkdown:"",
                closeDate: "",
                closeDateDisplay: "",
                closeDateTicks: 0,
                postDate: "",
                postDateDisplay: "",
                postDateTicks: 0,
                preferredSkills: [],
                requiredSkills: [],
                location: null
            },
            preview: {
                dialog: false
            },
            categories: {
                loading: false,
                items: []
            },
            types: {
                loading: false,
                items: []
            },
            companies: {
                loading: false,
                items: []
            },
            toolbar: [ "heading-3", "bold", "|", "unordered-list", "ordered-list", "|", "side-by-side", "guide", "|", "undo", "redo" ]
        }
    },
    mounted: async function () {
        if (this.id) {
            await this.loadPosting(this.id);
            this.location.items = [this.posting.location];
        }

        var result = await this.$api.get("company/getNameByUserId", this.$user);
        this.posting.company = result.data;

        this.loadCategories();
        this.loadTypes();
        this.posting.postDate = dateUtils.formatDateISO(new Date());

        this.$refs.descriptionMarkdown.easymde.toggleSideBySide();
    },
    created: function () {
    },
    computed: {
    },
    watch: {
        "posting.closeDate"(val) {
            var parsedDate = dateUtils.parseISO(val);
            if (parsedDate != "Invalid Date") {
                this.posting.closeDateDisplay = dateUtils.formatDateShort(parsedDate);
                this.posting.closeDateTicks = parsedDate.getTime();
            }
        },
        "posting.postDate"(val) {
            var parsedDate = dateUtils.parseISO(val);
            if (parsedDate != "Invalid Date") {
                this.posting.postDateDisplay = dateUtils.formatDateShort(parsedDate);
                this.posting.postDateTicks = parsedDate.getTime();
            }
        },
        async "location.search"(val) {
            await this.searchLocation(val);
        },
        "posting.description"(val) {
            var description = this.stripHtml(val);
            this.posting.descriptionText = description;
        }
    },
    methods: {
        processMarkdown() {
            var markdownEditor = this.$refs.descriptionMarkdown.easymde;
            var markdown = markdownEditor.markdown(markdownEditor.value());
            this.$set(this.posting, "description", markdown);
        },
        descriptionPreview(text) {
            var converter = new showdown.Converter();
            return converter.makeHtml(text);
        },  
        async publish() {
            this.publishing = true;
            await this.$api.post("job/upsert", this.posting, this.$user);
            console.log(this.posting);
            this.publishing = false;

            this.$bus.emit(events.APP_SNACKBAR_MESSAGE, "Job Published");
            
            setTimeout(() => {
                this.$router.push({ name: "job-list" });
            }, 1000);
        },
        cancel() {
            this.$router.push({ name: "job-list" });
        },
        async searchLocation(val, bypass) {
            if (val && val.length > 2) { // search on 3 or more characters
                if (this.posting.location && val == this.posting.location.display && !bypass) {
                    return;
                }
                                
                this.$set(this.location, "items", []);

                this.location.loading = true;

                var result = await this.$api.get("suburb/search?q=*" + val.split(" ").join("?") + "*", this.$user);
                if (result && result.data.length > 0) {
                    var items = [];
                    for (var record of result.data) {
                        var suburb = record._source;
                        suburb.id = record._id;
                        suburb.display = suburb.suburb + " " + suburb.state + " " + suburb.postcode;
                        items.push(Object.assign({}, suburb));
                    }
                    this.$set(this.location, "items", items);
                    console.log(this.location.items);
                }
                else {
                    this.$set(this.location, "items", []);
                }

                this.location.loading = false;
            }
            else {
                this.$set(this.location, "items", []);
            }
        },
        showPreview() {
            this.preview.dialog = true;
        }, 
        async loadPosting(id) {
            this.loading = true;
            var result = await this.$api.get("job/get?id=" + id, this.$user);
            var posting = result.data;
            if (!posting) {
                this.$bus.emit(events.APP_SNACKBAR_MESSAGE, "Job Posting Not Found");
                return;
            }

            this.$set(this, "posting", posting);
            this.loading = false;
        },  
        async loadCategories() {
            this.categories.loading = true;
            var result = await this.$api.get("reference/list?database=jobcategory", this.$user);
            var categories = result.data.docs.sort((a, b) => a.value <= b.value ? -1 : 1);
            this.$set(this.categories, "items", categories);
            this.categories.loading  = false;
        },
        async loadTypes() {
            this.types.loading = true;
            var result = await this.$api.get("reference/list?database=jobtype", this.$user);
            var types = result.data.docs.sort((a, b) => a.value <= b.value ? -1 : 1);
            this.$set(this.types, "items", types);
            this.types.loading = false;
        },
        stripHtml(html) {
            var clean = html.replace(/<[^>]+>/gm, ' ');
            return clean;
        },
        populateSkills() {
            var doc = nlp(this.posting.descriptionText);
            var foundSkills = doc.lookup(skills).normalize().unique();

            var skillsList = foundSkills.toLowerCase().toTitleCase().docs.map(match => match.map(term => term.pre + term.text + term.post).join("").trim());

            var matchedList = [];
            for (var skill of skillsList) {
                if (skills.includes(skill)) {
                    matchedList.push(skill);
                }
            }

            return matchedList;
        },
        populateRequiredSkills() {
            var skills = this.populateSkills();
            console.log(this.posting.requiredSkills);
            this.$set(this.posting, "requiredSkills", skills);
        },
        populatePreferredSkills() {
            this.posting.preferredSkills = this.populateSkills();
        }        
    },
    components: {
        jobSearchResult,
        jobPostingDetails,
        VueEasymde
    }
}
</script>