<style>
</style>

<template>
    <v-container class="elevation-4" fill-height style="align-items:flex-start;position:relative;">
        <v-container>
            <v-col>
                <v-row>
                    <h2>Open Tasks</h2>
                </v-row>                
                <v-row>
                    <v-data-table height="70vh" style="width:100%;" :calculate-widths="true" :headers="headers" :loading="loading" :items="items" class="elevation-2 mt-4" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
                        <template v-slot:item.search="{ item }">
                            <div class="mt-2" v-if="item.type == 'candidate'" style="font-weight:bold;">
                                Candidate application<v-chip x-small class="ml-2" :color="item.status == 'open' ? 'success' : (item.status == 'closed' ? 'error' : 'warning')">{{ item.status }}</v-chip><br/>
                                <span style="font-weight:normal;">
                                    Name: {{ item.data.firstName }} {{ item.data.lastName }}<br/>Email: <a :href="('mailto:' + item.data.email)">{{ item.data.email }}</a><br/>Phone number: {{ item.data.phoneNumber }}
                                </span>
                            </div>
                            <div class="mt-2" v-if="item.type == 'partner'" style="font-weight:bold;">
                                Partner Access to Company<v-chip x-small class="ml-2" :color="item.status == 'open' ? 'success' : (item.status == 'closed' ? 'error' : 'warning')">{{ item.status }}</v-chip><br/>
                                <span style="font-weight:normal;">
                                    Name: {{ item.data.firstName }} {{ item.data.lastName }}<br/>Email: <a :href="('mailto:' + item.data.email)">{{ item.data.email }}</a><br/>Requested Company: {{ item.data.company }}
                                </span>
                            </div>                            
                            <div class="mt-2" v-if="item.type == 'internal'" style="font-weight:bold;">
                                Request for Role Assignment<v-chip x-small class="ml-2" :color="item.status == 'open' ? 'success' : (item.status == 'closed' ? 'error' : 'warning')">{{ item.status }}</v-chip><br/>
                                <span style="font-weight:normal;">
                                    Name: {{ item.data.firstName }} {{ item.data.lastName }}<br/>Email: <a :href="('mailto:' + item.data.email)">{{ item.data.email }}</a><br/>Role note: {{ item.data.role }}
                                </span>
                            </div>
                            <div class="mt-2" v-if="item.type == 'job-application'" style="font-weight:bold;">
                                Job application for<v-chip x-small class="ml-2" :color="item.status == 'open' ? 'success' : (item.status == 'closed' ? 'error' : 'warning')">{{ item.status }}</v-chip><br/>
                                <router-link :to="{ name: 'job-details', params:{ id: item.data.job._id }}">{{ item.data.job.title }}</router-link><br/>
                                <span style="font-weight:normal;">
                                    Name: <router-link :to="{ name: 'profile-view', params:{ id: item.data._id }}">{{ item.data.firstName }} {{ item.data.lastName }} ({{ item.data.phoneNumber }})</router-link><br/>
                                    Email: <a :href="('mailto:' + item.data.email)">{{ item.data.email }}</a>
                                </span>
                            </div>
                        </template>
                        <template v-slot:item.type="{ item }">
                            <v-chip small>{{ item.type }}</v-chip>
                        </template>                         
                        <template v-slot:item.created="{ item }">
                            {{ formatDate(item.created) }}
                        </template>                        
                        <template v-slot:item.owner="{ item }">
                            {{ item.owner ? item.owner.name : "none" }}
                        </template>                        
                        <template v-slot:item.status="{ item }">
                            <v-btn @click="approve(item)" small :loading="item.loading" color="primary" width="90">Approve</v-btn>
                            <v-btn @click="reject(item)" small :loading="item.loading" color="secondary" width="90" class="mt-1">Reject</v-btn>
                        </template>
                        <template v-slot:top>
                            <v-text-field filled v-model="search" label="Search Tasks" class="mx-4 mt-4" hide-details clearable>
                                <template v-slot:append-outer>
                                    <v-btn @click="find()">Search</v-btn>
                                </template>
                            </v-text-field>
                            <div class="mt-2 ml-4">
                                <v-container class="d-inline-flex">
                                    <v-row>
                                        <v-col cols="2" class="pt-0 pb-0">
                                            <v-checkbox v-model="filterOptions.closed" label="Closed" hide-details class="mt-0"></v-checkbox>
                                        </v-col>
                                        <v-col cols="2" class="pt-0 pb-0">
                                            <v-checkbox v-model="filterOptions.rejected" label="Rejected" hide-details class="mt-0"></v-checkbox>
                                        </v-col>
                                        <v-col class="pt-0 pb-0">
                                            <v-checkbox v-model="filterOptions.mine" label="My Tasks Only" hide-details class="mt-0"></v-checkbox>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </div>
                        </template>                        
                    </v-data-table>
                </v-row>
            </v-col>
        </v-container>
        <v-dialog persistent v-model="partner.dialog" max-width="500px">
            <v-card>
                <v-card-title>
                    Select Company
                </v-card-title>
            <v-card-text>
                <div class="mb-4">
                    Please select a company to assign {{ selectedItem?.data.firstName }} {{ selectedItem?.data.lastName }} ({{ selectedItem?.data.email }}) to. They requested access to "{{ selectedItem?.data.company }}".
                </div>
                <v-select filled hide-details label="Company" v-model="partner.selected" :items="partner.companies" item-value="_id" item-text="name"></v-select>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer><v-btn color="primary" @click="savePartnerApproval" :loading="partner.loading">Save</v-btn><v-btn color="secondary" @click="cancelPartnerApproval" :disabled="partner.loading">Close</v-btn>
            </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog persistent v-model="internal.dialog" max-width="500px">
            <v-card>
                <v-card-title>
                    Select Role
                </v-card-title>
            <v-card-text>
                <div class="mb-4">
                    Please select a role to assign {{ selectedItem?.data.firstName }} {{ selectedItem?.data.lastName }} ({{ selectedItem?.data.email }}) to. They provided the following role information "{{ selectedItem?.data.role }}".
                </div>
                <v-select filled hide-details label="Role" v-model="internal.selected" :items="internal.roles" item-value="_id" item-text="name"></v-select>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer><v-btn color="primary" @click="saveInternalApproval" :loading="internal.loading">Save</v-btn><v-btn color="secondary" @click="cancelInternalApproval" :disabled="internal.loading">Close</v-btn>
            </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import events from "../../app/app.events";
import dateUtils from "../../util/date.utils";

import auth from "../../mixins/auth";

export default {
    mixins: [ auth ],
    props: {
        id: {}
    },
    data: function () {
        return {
            loading: false,
            headers: [
                { text: "Task Information", value: "search" },
                { text: "Type", value: "type", width: "100" },
                { text: "Owner", value: "owner", width: "120" },
                { text: "Created Date", value: "created", width: "130" },
                { text: "Actions", value: "status", width: "100" }
            ],
            items: [],
            sortBy: "value",
            sortDesc: false,
            search: "",
            partner: {
                dialog: false,
                companies: [],
                selected: "",
                loading: false                
            },
            internal: {
                dialog: false,
                roles: [],
                selected: "",
                loading: false
            },
            selectedItem: null,
            filterOptions: {
                closed: false,
                rejected: false,
                mine: true
            }
        }
    },
    mounted: async function () {
        var savedSearch = localStorage.getItem("savedTaskListSearch");
        if (savedSearch) {
            savedSearch = JSON.parse(savedSearch);

            if (savedSearch.searchText) {
                this.search = savedSearch.searchText;
            }

            if (savedSearch.filterOptions) {
                this.$set(this, "filterOptions", savedSearch.filterOptions);
            }
        }

        await this.find();
        await this.listCompanies();
        await this.listRoles();
    },
    created: function () {
    },
    computed: {
    },
    methods: {   
        async find() {
            var filters = [];
            console.log(this.filterOptions);
            if (!this.filterOptions.closed) {
                filters.push("NOT closed");
            } 
            if (!this.filterOptions.rejected) {
                filters.push("NOT rejected");
            } 

            var filter = filters.join(" AND ");

            var q = this.search;
            if (!q || q.length == 0) {
                q = "*";
            }
            else {
                q = "*" + q + "*";
            }

            if (this.filterOptions.mine) {
                q = q + " AND owner._id:" + this.$user.id;
            }

            if (filters.length > 0) {
                q = q + " AND status:(" + filter +")";
            }

            await this.loadTasks(q);

        },
        viewTask(item) {
            this.$router.push("/task/details/" + item.id);
        },
        formatDate(date) {
            return dateUtils.formatDateShort(date);
        },
        async listCompanies() {
            var result = await this.$api.get("company/listNames", this.$user);
            if (result) {
                this.$set(this.partner, "companies", result.data);
            }
        },
        async listRoles() {
            var result = await this.$api.get("role/list", this.$user);
            if (result) {
                this.$set(this.internal, "roles", result.data);
            }
        }, 
        async loadTasks(q) {
            this.loading = true;

            var savedSearch = {
                searchText: this.search,
                filterOptions: this.filterOptions
            };
            
            localStorage.setItem("savedTaskListSearch", JSON.stringify(savedSearch));

            var result = await this.$api.get("task/list?q=" + q, this.$user);
            console.log(result);
            if (result) {
                var items = [];
                for (var record of result.data) {
                    var task = record._source;
                    task.id = record._id;
                    task.loading = false;
                    if (task.type == "candidate") {
                        task.search = task.data.firstName + " " + task.data.lastName + " " + task.data.phoneNumber;
                    }
                    items.push(task);
                }               
                this.$set(this, "items", items);
            }
            this.loading = false;
        },
        async approve(item) {
            switch (item.type) {
                case "candidate":
                    this.approveCandidate(item);
                    break;
                case "partner":
                    this.approvePartner(item);
                    break;
                case "internal":
                    this.approveInternal(item);
                    break;
                case "job-application":
                    this.approveJobApplication(item);
                    break;                    
            }
        },
        async reject(item) {
            this.$set(item, "loading", true);
            
            var waiting = new Promise((resolve) => {
                this.$bus.emit(events.APP_DIALOG_OPEN, { title: "Reject this request?", message: "Rejecting " + item.data.firstName + " " + item.data.lastName + "'s request will mean they will not be able to perform any further actions in this system. Do you want to deny this request?", onConfirm: () => { resolve(true) }, onCancel: () => { resolve(false) } });   
            });

            var okay = await waiting;
            if (okay) {
                var result = await this.$api.get("task/reject?id=" + item.id, this.$user);
                if (result) {
                    this.$set(item, "owner", result.data.owner);
                }
                this.$bus.emit(events.APP_SNACKBAR_MESSAGE, "Request has been rejected");  
                this.$set(item, "status", "closed");
            }

            this.$set(item, "loading", false);
            
        },
        async approveCandidate(item) {
            this.$set(item, "loading", true);
            
            var waiting = new Promise((resolve) => {
                this.$bus.emit(events.APP_DIALOG_OPEN, { title: "Confirm Approval", message: "Approving " + item.data.firstName + " " + item.data.lastName + " will allow them to create a profile and apply for jobs. This will also assign them to you as a Pathway Officer. Would you like to continue?", onConfirm: () => { resolve(true) }, onCancel: () => { resolve(false) } });   
            });

            var okay = await waiting;
            if (okay) {
                var result = await this.$api.get("task/approveCandidate?id=" + item.id, this.$user);
                if (result) {
                    if (result.data.message) {
                        this.$bus.emit(events.APP_SNACKBAR_MESSAGE, result.data.message);  
                        this.$set(item, "loading", false);
                        return;
                    }

                    this.$set(item, "owner", result.data.owner);
                }
                this.$bus.emit(events.APP_SNACKBAR_MESSAGE, "Candidate approved and assigned");  
                this.$set(item, "status", "closed");
            }

            this.$set(item, "loading", false);
        },
        async approveJobApplication(item) {
            this.$set(item, "loading", true);
            
            var waiting = new Promise((resolve) => {
                this.$bus.emit(events.APP_DIALOG_OPEN, { title: "Confirm Job Application", message: "Approving " + item.data.firstName + " " + item.data.lastName + " will give access to profile information to " + item.data.job.company.name + " do you wish to confirm?", onConfirm: () => { resolve(true) }, onCancel: () => { resolve(false) } });   
            });

            var okay = await waiting;
            if (okay) {
                var result = await this.$api.get("task/approveJobApplication?id=" + item.id + "&job=" + item.data.job._id, this.$user);
                if (result) {
                    if (result.data.message) {
                        this.$bus.emit(events.APP_SNACKBAR_MESSAGE, result.data.message);  
                        this.$set(item, "loading", false);
                        return;
                    }

                    this.$set(item, "owner", result.data.owner);
                }
                this.$bus.emit(events.APP_SNACKBAR_MESSAGE, "Job Application Approved");  
                this.$set(item, "status", "closed");
            }

            this.$set(item, "loading", false);
        },        
        async approvePartner(item) {
            this.$set(item, "loading", true);
            
            this.partner.selected = "";
            this.selectedItem = item;
            this.partner.dialog = true;
        },
        async cancelPartnerApproval() {
            this.partner.dialog = false;
            this.$set(this.selectedItem, "loading", false);
        },
        async savePartnerApproval() {
            var item = this.selectedItem;

            if (this.partner.selected == "") {
                this.$bus.emit(events.APP_SNACKBAR_MESSAGE, "Please select a company before saving");
                return;
            }

            var result = await this.$api.get("task/approvePartner?id=" + item.id + "&companyId=" + this.partner.selected, this.$user);
            if (result) {
                if (result.data.message) {
                    this.$bus.emit(events.APP_SNACKBAR_MESSAGE, result.data.message);  
                    this.$set(item, "loading", false);
                    return;
                }

                this.$set(item, "owner", result.data.owner);
                this.$bus.emit(events.APP_SNACKBAR_MESSAGE, "Partner access approved");
                this.$set(item, "status", "closed");
            }
            else {
                this.$bus.emit(events.APP_SNACKBAR_MESSAGE, "Partner access approval failed!");
            }

            this.partner.dialog = false;
            this.$set(item, "loading", false);
        },
        async approveInternal(item) {
            this.$set(item, "loading", true);
            
            this.internal.selected = "";
            this.selectedItem = item;
            this.internal.dialog = true;
        },
        async cancelInternalApproval() {
            this.internal.dialog = false;
            this.$set(this.selectedItem, "loading", false);
        },
        async saveInternalApproval() {
            var item = this.selectedItem;

            if (this.internal.selected == "") {
                this.$bus.emit(events.APP_SNACKBAR_MESSAGE, "Please select a role before saving");
                return;
            }

            var result = await this.$api.get("task/approveInternal?id=" + item.id + "&role=" + this.internal.selected, this.$user);
            if (result) {
                this.$set(item, "owner", result.data.owner);
                this.$bus.emit(events.APP_SNACKBAR_MESSAGE, "Internal access approved");
                this.$set(item, "status", "closed");
            }
            else {
                this.$bus.emit(events.APP_SNACKBAR_MESSAGE, "Internal access approval failed!");
            }

            this.internal.dialog = false;
            this.$set(item, "loading", false);
        }

    },
    components: {
    }
}
</script>