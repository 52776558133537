<style scoped>
</style>

<template>
    <v-container class="elevation-4" fill-height style="align-items:flex-start;position:relative;">
        <v-container>
            <v-row>
                <v-col class="pb-0">
                    <h2>Welcome</h2>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    Welcome to the Soldier On Jobs Portal, a place where our participants can connect with veteran-supportive employment partners.
                </v-col>
            </v-row>
            <v-row>
                <v-col style="text-align: center;">
                    <img src="../../images/so-meet.png" />
                </v-col>
            </v-row>
            <v-row>
                <v-col style="text-align: center;">
                    Need assistance? Contact a Soldier On Pathways Officer for support. <v-btn color="primary" class="ml-2" href="mailto:employment@soldieron.org.au">Contact Us</v-btn>
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>

<script>
import auth from "../../mixins/auth";

export default {
    mixins: [ auth ],
    data: function () {
        return {
        }
    },
    mounted: async function () {
        console.log(this.$user.role);
        await this.$root.waiting;
        if (this.$role.tag == "pending") {
            this.$router.replace({ name: "welcome" });
        }
    },
    created: function () {
    },
    computed: {
    },
    methods: {      
    },
    components: {
    }
}
</script>