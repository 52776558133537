<style>

</style>

<template>
    <v-container class="elevation-4" fill-height style="align-items:flex-start;position:relative;">
        <v-overlay :value="loading">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-container class="pb-14">
            <v-row>
                <v-col class="pb-0">
                    <h2>{{ id ? "Edit" : "Add" }} a Company</h2>
                </v-col>
            </v-row>
            <v-col class="pt-4 pb-4" style="text-align:center;">
                <company-logo size="150" :logo="company.logo" @click="photo"></company-logo><br />
                <v-btn class="mt-4" @click="photo" :disabled="loading" color="primary">Set Logo</v-btn>
            </v-col>
            <v-row>
                <v-col class="pb-0">
                    <v-text-field filled label="Company Name" v-model="company.name" persistent-hint
                        hint="Name or trading name of the company" counter="100"></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="pb-2">
                    <v-select filled label="Pledge Level" v-model="company.pledgeLevel" hide-details
                        :menu-props="{ bottom: true, offsetY: true }" :loading="levels.loading" :items="levels.items"
                        item-text="value" item-value="_id" return-object></v-select>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="pb-0">
                    <v-textarea filled label="Description of Services" v-model="company.summary" persistent-hint
                        hint="A short description of services offered by the company. i.e. what does this company do?"
                        counter="1000"></v-textarea>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-card>
                        <v-card-title class="pt-1 pb-0" style="font-size:1.0rem">HR Contact</v-card-title>
                        <v-container>
                            <v-row>
                                <v-col cols="4" class="pb-0 pt-0">
                                    <v-text-field filled label="Name" v-model="company.hrContact.name"
                                        counter="100"></v-text-field>
                                </v-col>
                                <v-col cols="4" class="pb-0 pt-0">
                                    <v-text-field filled label="Email" type="email" v-model="company.hrContact.email"
                                        counter="100"></v-text-field>
                                </v-col>
                                <v-col cols="4" class="pb-0 pt-0">
                                    <v-text-field filled label="Phone" v-model="company.hrContact.phone"
                                        counter="100"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-card>
                        <v-card-title class="pt-1 pb-0" style="font-size:1.0rem">Industry Champion
                            Contact</v-card-title>
                        <v-container>
                            <v-row>
                                <v-col cols="4" class="pb-0 pt-0">
                                    <v-text-field filled label="Name" v-model="company.championContact.name"
                                        counter="100"></v-text-field>
                                </v-col>
                                <v-col cols="4" class="pb-0 pt-0">
                                    <v-text-field filled label="Email" type="email"
                                        v-model="company.championContact.email" counter="100"></v-text-field>
                                </v-col>
                                <v-col cols="4" class="pb-0 pt-0">
                                    <v-text-field filled label="Phone" v-model="company.championContact.phone"
                                        counter="100"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-col>
            </v-row>
            <v-row v-if="id">
                <v-col>
                    <v-card>
                        <v-card-title class="pt-1 pb-0" style="font-size:1.0rem">Assign Users</v-card-title>
                        <v-container>
                            <v-row>
                                <v-col class="pt-0">
                                    <v-data-table height="20vh" style="width:100%;" :headers="users.headers" :loading="users.loading" :items="users.items" class="elevation-2 mt-4">
                                        <template v-slot:item.actions="{ item }">
                                            <v-btn @click="removeUser(item)" small class="ml-1" color="secondary">Remove</v-btn>
                                        </template>
                                        <template v-slot:top>
                                            <v-btn :disabled="loading" small class="mt-2 ml-2"
                                                @click="showUserSearch" color="primary">Assign User</v-btn>
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <div style="position:fixed;bottom:0px;left:0px;width:100%;">
            <v-container class="pa-0">
                <v-card class="pt-4 pb-2 elevation-6">
                    <v-card-actions class="pt-0 d-flex justify-center">
                        <v-btn color="primary" @click="save" width="150" :loading="saving">
                            Save
                        </v-btn>
                        <v-btn color="secondary" @click="cancel" width="150" :disabled="saving">
                            Cancel
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-container>
        </div>
        <v-dialog persistent v-model="users.dialog" max-width="750px">
            <v-card>
                <v-card-title>
                    Search for a user to assign
                </v-card-title>
                <v-card-text>
                    <search-users v-model="users.selected" :show="users.dialog"></search-users>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer><v-btn color="primary" @click="assignUser"
                        :loading="users.dialogLoading">Assign</v-btn><v-btn color="secondary"
                        @click="users.dialog = false" :disabled="users.dialogLoading">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import events from "../../app/app.events";

import searchUsers from "./company.user.search.vue";

import companyLogo from "./company.logo.vue";

import auth from "../../mixins/auth";

export default {
    mixins: [ auth ],
    props: {
        id: {}
    },
    data: function () {
        return {
            company: {
                name: "",
                summary: "",
                pledgeLevel: null,
                hrContact: {
                    name: "",
                    email: "",
                    phone: ""
                },
                championContact: {
                    name: "",
                    email: "",
                    phone: ""
                }
            },
            users: {
                loading: false,
                headers: [
                    { text: "Name", value: "name" },
                    { text: "Email", value: "email" },
                    { text: "Actions", value: "actions", width: "170" }
                ],
                items: [],
                dialog: false,
                dialogLoading: false,
                selected: null
            },
            levels: {
                loading: false,
                items: []
            },
            saving: false,
            loading: false,
            logoError: false,
        }
    },
    mounted: async function () {
        if (this.id) {
            await this.loadCompany(this.id);
            await this.loadUsers(this.id);
        }
        this.loadPledgeLevels();
    },
    created: function () {
    },
    computed: {
    },
    methods: {
        imageError() {
            this.logoError = true;
        },
        async save() {
            this.saving = true;
            var result = await this.$api.post("company/upsert", this.company, this.$user);
            if (!result) {
                this.$bus.emit(events.APP_SNACKBAR_MESSAGE, "Error Saving Company");
                this.saving = false;
                return;
            }

            this.saving = false;

            this.$bus.emit(events.APP_SNACKBAR_MESSAGE, "Company Saved");
            setTimeout(() => {
                this.$router.push({ name: "company-list" });
            }, 1000);
        },
        cancel() {
            this.$router.push({ name: "company-list" });
        },
        showUserSearch() {
            this.users.dialog = true;
        },
        async assignUser() {
            this.users.dialogLoading = true;
            console.log(this.users.selected);
            await this.$api.post("user/assign", { userId: this.users.selected._id, company: this.id }, this.$user);
            this.users.items.push(this.users.selected);
            this.users.dialogLoading = false;
            this.users.dialog = false;
        },
        async removeUser(user) {
            this.users.dialogLoading = true;
            await this.$api.post("user/assign", { userId: user._id, company: null }, this.$user);

            var deleteIndex = this.users.items.findIndex(u => u._id == user._id);
            if (deleteIndex != -1) {
                this.$delete(this.users.items, deleteIndex);
            }

            this.users.dialogLoading = false;
        },
        async loadUsers(id) {
            this.users.loading = true;
            var result = await this.$api.get("user/search?q=company:" + id, this.$user);
            if (result) {
                var items = [];
                for (var record of result.data) {
                    var user = record._source;
                    user._id = record._id;
                    items.push(user);
                }
                this.$set(this.users, "items", items);
            }
            this.users.loading = false;
        },
        async loadCompany(id) {
            this.loading = true;
            var result = await this.$api.get("company/get?id=" + id, this.$user);
            var company = result.data;
            if (!company) {
                this.$bus.emit(events.APP_SNACKBAR_MESSAGE, "Company Not Found");
                return;
            }

            this.$set(this, "company", company);
            console.log(this.company);
            this.loading = false;
        },
        async loadPledgeLevels() {
            this.levels.loading = true;
            var result = await this.$api.get("reference/list?database=pledgelevel", this.$user);
            var levels = result.data.docs.sort((a, b) => a.value <= b.value ? -1 : 1);
            this.$set(this.levels, "items", levels);
            this.levels.loading = false;
        },
        getImage() {
            if (this.company.logo) {
                return this.$asset.getUrl(this.company.logo, "medium");
            }
            return "";
        },
        photo() {
            this.$bus.emit(events.APP_BOTTOM_SHEET_OPEN, {
                show: true, title: "Select Photo",
                button: { title: "edit", action: () => { }, show: false },
                config: {
                    file: "domains/photo/photo.image",
                    props: {
                        ratio: 1,
                        save: true,
                        round: false,
                        onCancel: this.onPhotoCancel.bind(this),
                        onDone: this.onPhotoDone.bind(this),
                        link: { ref: "company", refId: this.company._id, field: "logo" }
                    }
                }
            });
        },
        onPhotoCancel() {
            this.$bus.emit(events.APP_BOTTOM_SHEET_OPEN, { show: false });
        },
        async onPhotoDone(result) {
            this.$set(this.company, "logo", result.image);
            this.$bus.emit(events.APP_BOTTOM_SHEET_OPEN, { show: false });
        }
    },
    components: {
        searchUsers,
        companyLogo
    }
}
</script>