import { render, staticRenderFns } from "./profile.job.preferences.vue?vue&type=template&id=81833f00&"
import script from "./profile.job.preferences.vue?vue&type=script&lang=js&"
export * from "./profile.job.preferences.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../common/temp/node_modules/.pnpm/vue-loader@15.10.0_68125b3067bc9264b7f5e63a32c23d52/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports