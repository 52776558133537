<style>
</style>

<template>
    <v-container class="elevation-4" fill-height style="align-items:flex-start;position:relative;">
        <v-container class="pb-14">
            <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
                <v-col class="pb-0">
                    <h2 v-if="!type">Personal Details</h2>
                    <h2 v-if="type == 'candidate'">Apply for Candidate Access</h2>
                    <h2 v-if="type == 'partner'">Apply for Pledge Partner Access</h2>
                    <h2 v-if="type == 'internal'">Request a Soldier On Role Allocation</h2>
                </v-col>
            </v-row>
            <v-row v-if="!type">
                <v-col class="pt-4 pb-0" style="text-align:center;">
                    <person-avatar size="150" :avatar="$user.avatar" :name="$user.name" @click="photo"></person-avatar><br/>
                    <v-btn class="mt-4" @click="photo" :disabled="loading">Change Photo</v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="pt-4 pb-0">
                    <v-text-field filled v-model="profile.firstName" label="First Name" persistent-hint hint="Enter your first name" :rules="[profile.validateName]" counter="100"></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="pt-4 pb-0">
                    <v-text-field filled v-model="profile.lastName" label="Last Name" persistent-hint hint="Enter your last name" :rules="[profile.validateName]" counter="100"></v-text-field>
                </v-col>
            </v-row>
            <v-row v-if="(type == 'candidate' || !type)">
                <v-col class="pt-4 pb-0">
                    <v-text-field filled v-model="profile.phoneNumber" label="Phone Number" persistent-hint hint="Enter your phone number" :rules="[profile.validatePhoneNumber]" counter="100"></v-text-field>
                </v-col>
            </v-row>
            <v-row v-if="type == 'partner'">
                <v-col class="pt-4 pb-0">
                    <v-text-field filled v-model="company" label="Company" persistent-hint hint="Please enter the name of the company you work for so and administrator can find it in the system" counter="100"></v-text-field>
                </v-col>
            </v-row>
            <v-row v-if="type == 'internal'">
                <v-col class="pt-4 pb-0">
                    <v-text-field filled v-model="role" label="Role" persistent-hint hint="Please enter a short description of your role at Soldier On so appropriate access can be given" counter="100"></v-text-field>
                </v-col>
            </v-row>
            <v-row v-if="type == 'candidate'">
                <v-col class="pt-4 pb-0">
                    Clicking apply below will send your application for access to a Pathways Officer for review.<br/>Please allow for up to 24hrs for access to be granted.
                </v-col>
            </v-row>
            <v-row v-if="type == 'partner'">
                <v-col class="pt-4 pb-0">
                    Clicking apply below will send your application for access to Soldier On for review.<br/>Please allow for up to 24hrs for access to be granted.
                </v-col>
            </v-row>
            <v-row v-if="type == 'internal'">
                <v-col class="pt-4 pb-0">
                    Clicking apply below will send your application to an administrator to allocate you a role.<br/>Please allow for up to 24hrs for access to be granted.
                </v-col>
            </v-row>
            </v-form>        
        </v-container>        
        <div style="position:fixed;bottom:0px;left:0px;width:100%;">
            <v-container class="pa-0">
                <v-card class="pt-4 pb-2 elevation-6">
                    <v-card-actions class="pt-0 d-flex justify-center">
                        <v-btn v-if="type" color="primary" @click="apply" width="150" :loading="saving">
                            Apply
                        </v-btn>
                        <v-btn v-else color="primary" @click="save" width="150" :loading="saving">
                            Save
                        </v-btn>
                        <v-btn color="secondary" @click="cancel" width="150" :disabled="saving">
                            Cancel
                        </v-btn>                        
                    </v-card-actions>
                </v-card>
            </v-container>
        </div>
    </v-container>
</template>

<script>
import events from "../../app/app.events";
import personAvatar from '../person/person.avatar.vue';

import Profile from "soldieron-data/contracts/profile.mjs";

import auth from "../../mixins/auth";

export default {
    mixins: [ auth ],
    props: {
        type: {}
    },
    data: function () {
        return {
            loading: false,
            saving: false,
            profile: new Profile({}),
            valid: true,
            new: true,
            company: "",
            role: "",
        }
    },
    mounted: function () {
        this.loadProfile();
    },
    created: function () {
    },
    computed: {
    },
    methods: {
        changeResume() {
            this.resumeBackup = this.resume;
            this.resume = null;
        },
        removeSkill(skill) {
            this.skills.splice(this.skills.indexOf(skill), 1);
        },
        async loadProfile() {
            var result = await this.$api.get("profile/getMine", this.$user);
            if (result.data) {
                var profileData = result.data;
                this.profile = new Profile(profileData);
                this.new = false;
            }
            console.log(this.profile);
        },
        getImage() {
            return this.$asset.getUrl(this.$user.avatar, "medium");
        },
        photo() {
            this.$bus.emit(events.APP_BOTTOM_SHEET_OPEN, { 
                show: true, title: "Select Photo", 
                button:{ title: "edit", action: () => {}, show: false }, 
                config: {
                    file: "domains/photo/photo.image", 
                    props: { 
                        ratio: 1, 
                        save: true, 
                        round: true, 
                        onCancel: this.onPhotoCancel.bind(this), 
                        onDone: this.onPhotoDone.bind(this), 
                        link: { ref: "user", refId: this.$user.id, field: "avatar" } 
                    }
                }
            });
        },
        async apply() {
            this.saving = true;
            await this.$api.post("profile/apply?type=" + this.type + "&role=" + this.role + "&company=" + this.company, this.profile, this.$user);
            this.saving = false;

            this.$bus.emit(events.APP_SNACKBAR_MESSAGE, "Your profile information has been saved and a request for review has been submitted to the Pathways team");
            setTimeout(() => {
                this.$router.push({ name: "welcome" });
            }, 1000);

        },
        async save() {
            if (!this.$refs.form.validate()) {
                return;
            }

            if (this.new) {
                this.profile._id = this.$user.id
            }

            this.saving = true;
            await this.$api.post("profile/upsert", this.profile, this.$user);
            this.saving = false;

            this.$bus.emit(events.APP_SNACKBAR_MESSAGE, "Profile Saved");
            setTimeout(() => {
                this.$router.push({ name: "profile-overview" });
            }, 1000);
        },
        cancel() {
            if (this.$user.role == 'pending') {
                this.$router.push({ name: "welcome" });
            }
            else {
                this.$router.push({ name: "profile-overview" });
            }
        },
        onPhotoCancel() {
            this.$bus.emit(events.APP_BOTTOM_SHEET_OPEN, { show: false });
        },
        async onPhotoDone() {
            await this.$security.reissueToken();
            this.$bus.emit(events.APP_BOTTOM_SHEET_OPEN, { show: false });
        }         
    },
    components: {
        personAvatar
    }
}
</script>