<style scoped lang="scss">
    .app-loader {
        position: relative;
        width: 100px;
        height: 100px;
    }
    .app-loader-bottom {
        position: absolute;
        top:0px;
        left:0px;
        width: 100%;
        height:100%;
        border-radius: 10rem;
        background: linear-gradient(var(--v-primary-base), var(--v-secondary-base));
        filter:blur(25px);
        box-shadow: 0px 0px 100px -50px black;
        animation: app-loader-animate 1s linear infinite;
    }

    .app-loader-bottom.done {
        animation: none;
        background: none;
    }

    .app-loader-top {
        position: absolute;
        background: #fff;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 80%;
        height: 80%;
        border-radius: 10rem;
        border: 0px solid white;
        box-shadow: 0px 0px 10px -3px rgba(0,0,0,0.6);
        display:flex;
        align-items: center;
        justify-content: center;
    }

    @keyframes app-loader-animate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
</style>

<template>
    <div class="app-loader">
        <div class="app-loader-bottom" :class="{ done: state != 'loading' }"></div>
        <div class="app-loader-top">
            <img v-if="state == 'loading'" src="../../images/so-logo-small.png" style="width:60px;filter:invert(1);" />
            <v-icon v-else-if="state == 'success'" size="60" color="green">mdi-check-circle</v-icon>
            <v-icon v-else size="60" color="red">mdi-close-circle</v-icon>
        </div>                
    </div>
</template>

<script>
//import events from "../../app/app.events";
export default {
    props: {
        state: { default: "loading" }
    },
    data: function () {
        return {
        }
    },
    mounted: function () {
    },
    created: function () {
    },
    computed: {
    },
    methods: {
    },
    components: {
    }
}
</script>