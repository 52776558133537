<style scoped>
</style>

<template>
    <v-container class="elevation-4" fill-height style="align-items:flex-start;position:relative;">
        <v-container>
            <v-row>
                <v-col>
                    <h2>Welcome!</h2>
                    Welcome to the Soldier On Jobs Portal.<br/>
                    Please choose the activity below that best represents what you want to do here now you're logged in.
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-card>
                        <v-card-title>I am looking for a job</v-card-title>
                        <v-card-text class="pb-0">
                            You have logged into the jobs portal to create a profile and start searching for job opportunities with the assistance of a pathways officer. The next steps will be to apply for candidate access which once granted will allow you to create a profile, upload your resume, fill in your skills, and then get to searching.                    
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" @click="applyCandidate">Apply for Candidate Access <v-icon>mdi-arrow-right</v-icon></v-btn>
                        </v-card-actions>                        
                    </v-card>
                </v-col>
            </v-row>            
            <v-row>
                <v-col>
                    <v-card>
                        <v-card-title>I work for a pledge partner</v-card-title>
                        <v-card-text class="pb-0">
                            You have logged into the jobs portal because you work for a pledge partner and want to post jobs on behalf of your company. The next steps will be to have an administrator associate you with a company so that you can start creating jobs on their behalf.
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" @click="requestPartner">Request Partner Access <v-icon>mdi-arrow-right</v-icon></v-btn>
                        </v-card-actions>                        
                    </v-card>
                </v-col>
            </v-row>            
            <v-row>
                <v-col>
                    <v-card>
                        <v-card-title>I work for Soldier On</v-card-title>
                        <v-card-text class="pb-0">
                            You have logged into the jobs portal because you work Soldier On in either a pathways or administrative capability and you need to be given a role in the system. The next steps will be to have an administrator set your role within the portal.
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" @click="requestRole">Request Role<v-icon>mdi-arrow-right</v-icon></v-btn>
                        </v-card-actions>                        
                    </v-card>
                </v-col>
            </v-row>            
        </v-container>
    </v-container>
</template>

<script>
import auth from "../../mixins/auth";

export default {
    mixins: [ auth ],
    data: function () {
        return {
        }
    },
    mounted: function () {
    },
    created: function () {
    },
    computed: {
    },
    methods: {
        applyCandidate() {
            this.$router.push({ name: "profile-personal-details", params: { type: "candidate" } });
        },
        requestPartner() {
            this.$router.push({ name: "profile-personal-details", params: { type: "partner" } });
        },
        requestRole() {
            this.$router.push({ name: "profile-personal-details", params: { type: "internal" } });
        }
    },
    components: {
    }
}
</script>