<style>

</style>

<template>
    <v-container class="elevation-4" fill-height style="align-items:flex-start;position:relative;">
        <v-container>
            <v-col>
                <v-row>
                    <h2>Manage Companies</h2>
                </v-row>
                <v-row>
                    <v-data-table height="70vh" style="width:100%;" :calculate-widths="true" :headers="headers"
                        :loading="loading" :items="items" class="elevation-2 mt-4" :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc">
                        <template v-slot:top>
                            <v-text-field filled v-model="search" label="Search Companies" class="mx-4 mt-4" hide-details clearable>
                                <template v-slot:append-outer>
                                    <v-btn @click="find()">Search Companies</v-btn>
                                </template>
                            </v-text-field>
                            <v-btn :disabled="loading" small class="mt-2 ml-4" @click="addCompany" color="primary">Add New Company</v-btn>
                        </template>                        
                        <template v-slot:item.name="{ item }">
                            <div class="mt-2" style="font-weight:bold;">{{ item.name }}</div>
                            <div class="mt-1 mb-2">{{ item.summary }}</div>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-btn @click="editCompany(item)" small width="90" class="mt-1" color="primary">Edit</v-btn>
                            <v-btn @click="deleteCompany(item)" small class="mb-1 mt-1" width="90" color="secondary">Delete</v-btn>
                        </template>
                    </v-data-table>
                </v-row>
            </v-col>
        </v-container>
    </v-container>
</template>

<script>
import events from "../../app/app.events";
import auth from "../../mixins/auth";

export default {
    mixins: [ auth ],
    props: {
        id: {}
    },
    data: function () {
        return {
            search: "",
            loading: false,
            headers: [
                { text: "Company Details", value: "name" },
                { text: "Pledge Level", value: "pledgeLevel.value", width: "120" },
                { text: "Actions", value: "actions", width: "170" }
            ],
            items: [],
            sortBy: "value",
            sortDesc: false,
        }
    },
    mounted: async function () {
        await this.loadCompanies("*");
    },
    created: function () {
    },
    computed: {
    },
    watch: {
        show(val) {
            console.log(val);
            if (!val) {
                this.searchText = "",
                    this.$set(this, "users", []);
            }
        }
    },
    methods: {
        async find() {
            var q = this.search;
            if (!q || q.length == 0) {
                q = "*";
            }
            else {
                q = this.search.includes(":") || this.search.includes("*") ? this.search : "*" + this.search + "*";
            }

            await this.loadCompanies(q);
        }, 
        addCompany() {
            this.$router.push({ name: "company-details" });
        },
        editCompany(item) {
            this.$router.push({ name: "company-details", params: { id: item.id } });
        },
        async deleteCompany(item) {
            var waiting = new Promise((resolve) => {
                this.$bus.emit(events.APP_DIALOG_OPEN, { title: "Confirm Company Delete", message: "Are you sure you want to delete this company?", onConfirm: () => { resolve(true) }, onCancel: () => { resolve(false) } });
            });

            var okay = await waiting;
            if (okay) {
                await this.$api.delete("company/delete?id=" + item.id, this.$user);
                setTimeout(() => {
                    this.loadCompanies("*");
                }, 500);
            }
        },
        async loadCompanies(q) {
            this.loading = true;
            var result = await this.$api.get("company/search?q=" + q, this.$user);
            if (result) {
                var items = [];
                for (var record of result.data) {
                    var company = record._source;
                    company.id = record._id;
                    items.push(company);
                }
                this.$set(this, "items", items);
            }
            this.loading = false;
        }
    },
    components: {
    }
}
</script>