<style>
    .v-text-field.v-text-field--solo.outer-button .v-input__append-outer {
        margin-top:6px !important;
    }

    .profile-summary {
        height:70px;
        text-overflow:ellipsis;
        overflow:hidden;
        display: -webkit-box !important;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        white-space: normal;
    }
</style>

<template>
    <v-container fill-height class="elevation-4 pa-4">
        <div style="width:100%;position:relative;">
            <h2 class="mb-2">Search for Profiles</h2>
            <v-data-iterator :items="profiles" hide-default-footer :loading="loading" style="width:100%">
                <template v-slot:header>
                    <v-text-field v-model="searchText" @keydown.enter.prevent="search" clearable flat solo-inverted hide-details prepend-inner-icon="mdi-magnify" class="mb-8 outer-button" label="Search">
                        <template v-slot:append-outer style="margin-top:2px;">
                            <v-btn @click="search" :loading="loading">Search</v-btn>
                        </template>
                    </v-text-field>
                </template>
                <template v-slot:default="props">
                    <v-row>
                        <v-col v-for="item in props.items" :key="item._id" cols="12" class="pt-0 pb-0">
                            <v-card class="pa-4 mb-4 mt-0" elevation="0" style="border:var(--border);" @click="viewProfile(item)">
                                <v-card-title class="pa-0"><strong>{{item._source.firstName}} {{item._source.lastName}}</strong><v-spacer></v-spacer><v-btn @click.stop.prevent="download(item)" v-if="item._source._attachments">{{getFilename(item._source._attachments)}}</v-btn></v-card-title>
                                <p style="margin-top:10px;" v-html="item._source.about" class="profile-summary"></p>
                                <div>
                                    <v-chip small class="ma-1" v-for="(skill,i) in getSkillsTruncated(item._source.skills)" :key="i">{{skill}}</v-chip>
                                </div>
                            </v-card> 
                        </v-col>
                    </v-row>
                </template>            
            </v-data-iterator>
        </div>
    </v-container>
</template>

<script>
import skills from "../../skills.json";

import auth from "../../mixins/auth";

export default {
    mixins: [ auth ],
    data: function () {
        return {
            searchText: "",
            profiles: [],
            loading: false,
        }
    },
    mounted: function () {
        var test = skills.data.sort((a, b) => {
            if (a.subcategory && b.subcategory && a.subcategory.name < b.subcategory.name) {
                return -1;
            }
            else if (a.subcategory && b.subcategory && a.subcategory.name > b.subcategory.name) {
                return 1;
            }
            else {
                if (a.name < b.name) {
                    return -1;
                }
                else {
                    return 1;
                }
            }
        });

        var skillsAutocomplete = [];
        for (var skill of test) {
            skillsAutocomplete.push({ n: skill.name, c: skill.subcategory ? skill.subcategory.name : "Uncategorized" });
        }

        var savedSearch = localStorage.getItem("savedProfileSearch");
        if (savedSearch) {
            savedSearch = JSON.parse(savedSearch);

            if (savedSearch.searchText) {
                this.searchText = savedSearch.searchText;
            }
            if (savedSearch.advancedText) {
                this.advancedText = savedSearch.advancedText;
            }

            this.search(true);
        }
    },
    created: function () {
    },
    computed: {
    },
    methods: {
        getFilename(attachment) {
            return Object.keys(attachment)[0];
        },
        getSkillsTruncated(skills) {
            if (!skills) {
                return [];
            }
            var topSkills = skills.slice(0, 10);
            var moreCount = skills.length - 10;
            if (moreCount > 0) {
                topSkills.push(moreCount + " more ...");
            }
            return topSkills;
        },
        async search() {
            if (!this.searchText) {
                this.searchText = "";
            }
            var query = this.searchText.includes(":") || this.searchText.includes("*") ? this.searchText : "*" + this.searchText + "*";


            this.loading = true;

            var savedSearch = {
                searchText: this.searchText
            };
            
            localStorage.setItem("savedProfileSearch", JSON.stringify(savedSearch));

            var result = await this.$api.get("profile/search?q=" + query, this.$user);

            if (result.data.length <= 0) {
                console.log("No results");
                this.profiles = [];
                this.loading = false;
                return;
            }

            this.profiles = result.data;            
            this.loading = false;
        },
        async download(profile) {
            var result = await this.$api.get("profile/downloadFile?profileId=" + profile._id, this.$user);
            var record = result.data;

            if (record) {
                var filename = Object.keys(record)[0];
                var details = record[filename];
                var source = "data:" + details.content_type + ";base64," + details.data;
                var anchor = document.createElement("a");
                
                anchor.href = source;
                anchor.download = filename;
                anchor.click();
            }
        },
        viewProfile(item) {
            this.$router.push({ name: "profile-view", params: { id: item._id }});
            //item._id            
        }
    },
    components: {
    }
}
</script>