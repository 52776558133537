<style>

</style>

<template>
    <v-container class="elevation-4" fill-height style="align-items:flex-start;position:relative;">
        <v-container>
            <v-col>
                <v-row>
                    <h2>Manage Users</h2>
                </v-row>
                <v-row>
                    <v-data-table height="70vh" style="width:100%;" :calculate-widths="true" :headers="headers"
                        :loading="loading" :items="users" item-key="_id" class="elevation-2 mt-4"
                        :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
                        <template v-slot:top>
                            <v-text-field filled v-model="searchText" label="Search Users" class="mx-4 mt-4" hide-details clearable>
                                <template v-slot:append-outer>
                                    <v-btn @click="search()">Search Users</v-btn>
                                </template>
                            </v-text-field>
                        </template>                        
                        <template v-slot:item.name="{ item }">
                            <div class="mt-2" style="font-weight:bold;">{{ item.name }}</div>
                            <div class="mt-1 mb-2">{{ item.summary }}</div>
                        </template>
                        <template v-slot:item.role="{ item }">
                            <v-menu>
                                <template v-slot:activator="{ on }">
                                    <v-chip v-on="on">{{ lookupRoleName(item.role) }}</v-chip>
                                </template>
                                <v-list>
                                    <v-list-item v-for="(role, index) in roles" :key="index">
                                        <v-chip @click="changeRole(role._id, item)">{{ role.name }}</v-chip>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-row>
                                <v-btn @click="toggleUserDisabled(item)" small color="primary">Suspend</v-btn>
                            </v-row>

                        </template>
                        <template v-slot:item.company="{ item }">
                            {{ lookupCompanyName(item.company) }}
                        </template>
                    </v-data-table>
                </v-row>
            </v-col>
        </v-container>
    </v-container>
</template>

<script>
import events from "../../app/app.events";

import auth from "../../mixins/auth";

export default {
    mixins: [ auth ],
    props: {
        id: {}
    },
    data: function () {
        return {
            loading: false,
            searchText: "",
            users: [],
            companies: [],
            roles: [],
            headers: [
                { text: "Name", value: "name" },
                { text: "Email", value: "email" },
                { text: "Company", value: "company" },
                { text: "Role", value: "role" },
                { text: "Suspended", value: "suspended" },
                { text: "Actions", value: "actions" }
            ],
            sortBy: "value",
            sortDesc: false,
        }
    },
    mounted: async function () {
        await this.listCompanies();
        await this.listRoles();
    },
    created: function () {
    },
    computed: {
    },
    watch: {
        selected(val) {
            this.$emit("input", val[0]);
        },
        show(val) {
            console.log(val);
            if (!val) {
                this.searchText = "",
                    this.$set(this, "users", []);
            }
        }
    },
    methods: {
        lookupCompanyName(id) {
            var company = this.companies.find(company => company._id == id);
            if (company) {
                return company.name;
            }
            return "Unassigned";
        },
        async listCompanies() {
            var result = await this.$api.get("company/listNames", this.$user);
            if (result) {
                this.$set(this, "companies", result.data);
            }
        },
        async search() {
            this.loading = true;
            var result = await this.$api.get("user/search?q=*" + this.searchText + "*", this.$root.user);
            if (result) {
                var items = [];
                for (var record of result.data) {
                    var user = record._source;
                    user._id = record._id;
                    items.push(user);
                }
                this.$set(this, "users", items);
            }

            this.loading = false;
        },
        async toggleUserDisabled(item) {
            var waiting = new Promise((resolve) => {
                this.$bus.emit(events.APP_DIALOG_OPEN, { title: "Confirm User Disable", message: "Are you sure you want to disable this user? They can be renabled at a later date", onConfirm: () => { resolve(true) }, onCancel: () => { resolve(false) } });
            });
            var okay = await waiting;
            if (okay) {
                await this.$api.post("user/disable", { userId: item._id }, { token: this.$root.authToken });
                this.$set(item, "suspended", !item.suspended);
            }
        },
        async changeRole(roleId, item) {
            this.loading = true;
            await this.$api.post("user/assignRole", { userId: item._id, roleId: roleId }, { token: this.$root.authToken });
            this.$set(item, "role", roleId);
            this.loading = false;
            this.$bus.emit(events.APP_SNACKBAR_MESSAGE, "Role changed");
        },

        async listRoles(){
            var result = await this.$api.get("role/list", this.$user);
            if (result) {
                this.$set(this, "roles", result.data);
            }
        },
        
        lookupRoleName(roleId){
            var role = this.roles.find(role => role._id == roleId);
            if (role) {
                return role.name;
            }
            return "Unassigned";
        }




    },
    components: {
    }
}
</script>