import events from "../app/app.events";

var authMixin = {
    mounted: async function() {
        if (!this.$route.meta.auth) {
            this.$bus.emit(events.APP_AUTH_SHOW, false);
            return;
        }

        if (this.$root.waiting) {
            await this.$root.waiting;
        }
        
        var deny = false;

        for (var action of this.$route.meta.auth) {
            if (!this.$role.actions.includes(action)) {
                deny = true;
            }
        }

        this.$bus.emit(events.APP_AUTH_SHOW, deny);
    }
}

export default authMixin;