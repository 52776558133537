<style>
.CodeMirror,
.CodeMirror-scroll,
.editor-preview-side {
    max-height: 500px;
}

.markdown-render ul,ol {
    margin-bottom: 16px;
}
</style>

<template>
    <v-container class="elevation-4 pa-4" style="color:black;">
        <v-row>
            <v-col class="pt-3 pb-0" style="max-width:80px;" v-if="item.company.logo && !logoError">
                <company-logo size="70" :logo="item.company.logo" v-on:logoerror="logoError = true"></company-logo>          
            </v-col>
            <v-col class="pb-1">
                <h2 style="line-height:40px;">{{ item.title ? item.title : "No Title Set" }}</h2>
                <h3 style="line-height:30px;">{{ item.category ? item.category.value : "No Category Selected" }}</h3>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" class="pb-0">
                <v-chip small color="primary" label text-color="white">
                    <v-icon left small>mdi-map-marker</v-icon>
                    {{ item.location ? item.location.display : "Unknown" }}
                </v-chip>                 
                <v-chip small color="secondary" label text-color="white" class="ml-0 ml-sm-2 mt-1 mt-sm-0">
                    {{ item.type ? item.type.value : "No Type" }}
                </v-chip>  
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <h4>Working for {{ item.company ? item.company.name : "No Company Selected" }}</h4>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="pb-0 pt-0">
                <h4>Summary</h4>
                {{ item.summary }}
            </v-col>
        </v-row>            
        <v-row v-if="short">
            <v-col class="pb-4 pt-0">
            </v-col>
        </v-row>            
        <template v-if="!short">
            <v-row>
                <v-col class="pb-0">
                    <h4>Description</h4>
                    <div v-html="item.description" class="markdown-render"></div>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="pt-0">
                    <h4>Required Skills</h4>
                    <v-chip small class="ma-1" v-for="(skill,i) in item.requiredSkills" :key="i">{{ skill }}</v-chip>
                </v-col>
            </v-row>            
            <v-row>
                <v-col class="pt-0">
                    <h4>Preferred Skills</h4>
                    <v-chip small class="ma-1" v-for="(skill,i) in item.preferredSkills" :key="i">{{ skill }}</v-chip>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="pt-0">
                    <v-chip class="ml-2" small color="grey" label text-color="white">
                        Posted {{ item.postDateDisplay }}
                    </v-chip>
                    <v-chip class="ml-2" small color="grey" label text-color="white">
                        Closing {{ item.closeDateDisplay }}   
                    </v-chip> 
                </v-col>
            </v-row>
        </template>         
    </v-container>
</template>

<script>
import companyLogo from '../company/company.logo.vue';

import auth from "../../mixins/auth";

export default {
    mixins: [ auth ],
    props: {
        item: {},
        short: { default: false }
    },
    data: function () {
        return {
            logoError: false
        }
    },
    mounted: function () {
    },
    created: function () {
    },
    computed: {
    },
    methods: {
    },
    components: {
        companyLogo
    }
}
</script>