<style scoped>
.job-header {
    white-space: nowrap;
    word-break: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight:bold;
    font-size:16px;
    line-height:20px;
    text-overflow:ellipsis;
    overflow:hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: normal;    
}

.job-summary {
    height:70px;
    text-overflow:ellipsis;
    overflow:hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: normal;
}
</style>

<template>
    <v-card class="ml-1 mr-1 mt-1" style="position:relative;" @click="$emit('click', item)">
        <div style="position:absolute;right:6px;bottom:6px;">
            <v-tooltip bottom v-if="getPreferredStatus(item)">
                <template #activator="{ on }">
                    <v-icon color="#ffcc00" v-on="on" class="mr-1">mdi-trophy-award</v-icon>
                </template>
                <span>You have been marked as a preferred applicant by the listing company!</span>
            </v-tooltip>

            <v-tooltip bottom v-if="item.applicants && item.applicants[$user.id]">
                <template #activator="{ on }">
                    <v-icon color="green" v-on="on">mdi-checkbox-marked-circle</v-icon>
                </template>
                <span>You applied on {{ formatDate(item.applicants[$user.id]) }}</span>
            </v-tooltip>
        </div>

        <v-card-title style="font-size:14px;" class="pb-3 pt-4">
            <v-row>
                <v-col class="pt-3 pb-0" style="max-width:60px;" v-if="item.company.logo">
                    <company-logo size="50" :logo="item.company.logo"></company-logo>          
                </v-col>
                <v-col class="pb-1">
                    <div class="job-header">{{ item.title ? item.title : "No Title Set" }}</div>
                    <v-chip small color="secondary" label text-color="white">
                        {{ item.type ? item.type.value : "No Type" }}
                    </v-chip>      
                    <v-chip small color="primary" label text-color="white" class="ml-2" v-if="!$vuetify.breakpoint.xsOnly">
                        <v-icon left small>mdi-map-marker</v-icon>
                        {{ item.location ? item.location.display : "Unknown" }}
                    </v-chip>
                </v-col>
            </v-row>
        </v-card-title>
        <v-row v-if="$vuetify.breakpoint.xsOnly">
            <v-col class="pb-1 pl-5 pr-5">
                <v-chip small color="primary" label text-color="white" class="ml-2">
                    <v-icon left small>mdi-map-marker</v-icon>
                    {{ item.location ? item.location.display : "Unknown" }}
                </v-chip>
            </v-col>
        </v-row>
        <v-card-text class="pb-2" style="color:black;">
            <div class="job-summary">
                {{ item.summary }}
            </div>
        </v-card-text>
        <v-card-title style="font-size:12px;" class="pt-0 pb-6">
            <v-row>
                <v-col class="pt-0 pb-5" style="height:28px;">
                    <v-chip small color="grey" label text-color="white" style="font-size:11px;">
                        Posted {{ item.postDateDisplay }}
                    </v-chip>
                    <v-chip small color="grey" label text-color="white" style="font-size:11px;" class="ml-2">
                        Closing {{ item.closeDateDisplay }}   
                    </v-chip>                 
                </v-col>
            </v-row>
        </v-card-title>
    </v-card>
</template>

<script>
import companyLogo from '../company/company.logo.vue';

import dateUtils from "../../util/date.utils";

import auth from "../../mixins/auth";

export default {
    mixins: [ auth ],
    props: {
        item: {},
        editable: {}
    },
    data: function () {
        return {
        }
    },
    mounted: function () {
    },
    created: function () {
    },
    computed: {
    },
    methods: {
        formatDate(ticks) {
            return dateUtils.formatDateShort(ticks);
        },
        getPreferredStatus(item) {
            if (item.potentialCandidates) {
                var found = item.potentialCandidates.find(c => c._id == this.$user.id);
                if (found) {
                    return true;
                }
            }
            return false;
        }
    },
    components: {
        companyLogo
    }
}
</script>