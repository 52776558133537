<style scoped>
</style>

<template>
    <div></div>
</template>

<script>
//import config from "../../app/app.config";

export default { 
    data: function () {
        return {
        }
    },
    mounted: function () {
        this.$security.clearToken();
        location.href = process.env.VUE_APP_LOGOUT_URI;
    },
    created: function () {
    },
    computed: {
    },
    methods: {
    },
    components: {
    }
}
</script>