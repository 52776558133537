<style scoped>
    .advanced-search-overlay {
        position: absolute;
        z-index: 1;
        top: 52px;
    }
    .advanced-search-sidebar {
        position: absolute;
        z-index: 1;
        left: 101%;
        top: 6px;
    }
</style>

<template>
    <v-container fill-height class="elevation-4 pa-4" ref="container">
        <div style="width:100%;position:relative;">
            <h2 class="mb-2">Search for Jobs</h2>
            <v-text-field v-model="searchText" @keydown.enter.prevent="search" clearable flat solo-inverted hide-details prepend-inner-icon="mdi-magnify" class="mb-4 outer-button" label="Search">
                <template v-slot:append-outer style="margin-top:2px;">
                    <v-btn @click="search(false)" :loading="loading">Search</v-btn>
                    <v-btn @click="toggleAdvanced" dark :disabled="loading" class="ml-2 pl-0 pr-0" style="min-width:36px;"><v-icon>mdi-text-search-variant</v-icon></v-btn>
                </template>
            </v-text-field>
            <div :class="advancedSearchClass" :style="{ width: advancedSearchWidth }" v-if="advanced.show" >
                <v-card elevation="8" class="pa-4" dark>
                    <b>Search Options</b>
                    <v-container>
                        <v-row>
                            <v-col>
                                <v-combobox dense filled multiple label="Job Categories" v-model="advanced.category.value" :items="advanced.category.items" clearable hide-details></v-combobox>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-combobox dense filled multiple label="Job Type" v-model="advanced.type.value" :items="advanced.type.items" clearable hide-details></v-combobox>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-select dense filled multiple label="State" v-model="advanced.state.value" :items="advanced.state.items" clearable hide-details item-text="name" item-value="id"></v-select>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </div>
            <v-virtual-scroll v-if="!loading && (jobs && jobs.length > 0)" :bench="1" :items="jobs" :height="height" :item-height="itemHeight">
                <template v-slot:default="{ item }">
                    <job-search-result :item="item" :key="item._id" v-on:click="open"></job-search-result>
                </template>
            </v-virtual-scroll>
        </div>
    </v-container>
</template>

<script>
import jobSearchResult from "../job/job.search.result.vue";

import auth from "../../mixins/auth";

export default {
    mixins: [ auth ],
    data: function () {
        return {
            searchText: "",
            advancedText: "",
            jobs: [],
            loading: false,
            height: 500,
            company: null,
            advanced: {
                show: false,
                category: {
                    value: "",
                    loading: false,
                    items:[]
                },
                type: {
                    value: "",
                    loading: false,
                    items:[]
                },
                state: {
                    value: "",
                    items:[
                        { id: "ACT", name: "Australian Capital Territory" },
                        { id: "NSW", name: "New South Wales" },
                        { id: "NT", name: "Northern Territory" },
                        { id: "QLD", name: "Queensland" },
                        { id: "SA", name: "South Australia" },
                        { id: "TAS", name: "Tasmania" },
                        { id: "VIC", name: "Victoria" },
                        { id: "WA", name: "Western Australia" }
                    ]
                }
            }
        }
    },
    mounted: function () {
        var savedSearch = localStorage.getItem("savedSearch");
        if (savedSearch) {
            savedSearch = JSON.parse(savedSearch);

            if (savedSearch.searchText) {
                this.searchText = savedSearch.searchText;
            }
            if (savedSearch.advancedText) {
                this.advancedText = savedSearch.advancedText;
            }

            this.search(true);
        }
        

        this.getCompany();

        this.loadCategories();
        this.loadTypes();

        this.height = this.$refs.container.offsetHeight - 100;
    },
    created: function () {
    },
    computed: {
        itemHeight() {
            return this.$vuetify.breakpoint.xsOnly ? 220 : 185;
        },
        advancedSearchClass() {
            return this.$vuetify.breakpoint.mdAndDown ? ["advanced-search-overlay"] : ["advanced-search-sidebar"];
        },
        advancedSearchWidth() {
            return this.$vuetify.breakpoint.mdAndDown ? "100%" : ((this.$vuetify.breakpoint.width - 850) / 2) + "px";
        }
    },
    methods: {
        async getCompany() {
            var result = await this.$api.get("company/getNameByUserId", this.$user);
            this.company = result.data;
        },
        getAdvancedText() {
            var searches = [];
            searches.push(this.getTermOptions(this.advanced.category.value, "category.value"));
            searches.push(this.getTermOptions(this.advanced.type.value, "type.value"));
            searches.push(this.getTermOptions(this.advanced.state.value, "location.state"));

            searches = searches.filter((search) => search);
            return searches.join(" AND ");
        },
        getTermOptions(term, field) {
            if (term) {
                var search = term.join("\" OR \"");
                if (search.length == 0) {
                    return null;
                }
                search = field + ":(\"" + search + "\")";
                return search;
            }
            return null;
        },
        toggleAdvanced() {
            this.advanced.show = !this.advanced.show;
        },
        async search(saved) {
            if (this.$vuetify.breakpoint.mdAndDown) {
                this.advanced.show = false;
            }

            var advanced;
            if (saved) {
                advanced = this.advancedText;
            }
            else {
                advanced = this.getAdvancedText();
                this.advancedText = advanced;
            }

            this.loading = true;
            if (!this.searchText) {
                this.searchText = "";
            }
            var query = this.searchText.includes(":") || this.searchText.includes("*") ? this.searchText : "*" + this.searchText + "*";

            var savedSearch = {
                searchText: this.searchText
            };

            if (advanced.length > 0) {
                query += " AND " + advanced;
                savedSearch.advancedText = advanced;
            }
            
            localStorage.setItem("savedSearch", JSON.stringify(savedSearch));

            var result = await this.$api.post("job/search", {q: query}, this.$user);

            if (result) {
                var items = [];
                for (var record of result.data) {
                    var job = record._source;
                    job.id = record._id;
                    items.push(job);
                }               
                this.$set(this, "jobs", items);
            }

            this.loading = false;
        },
        open(item) {
            this.$router.push({ name: "job-details", params: { item: item, id: item.id }});
        },
        async loadCategories() {
            this.advanced.category.loading = true;
            var result = await this.$api.get("reference/list?database=jobcategory", this.$user);
            var categories = result.data.docs.sort((a, b) => a.value <= b.value ? -1 : 1);
            categories = categories.map(category => category.value);
            this.$set(this.advanced.category, "items", categories);
            this.advanced.category.loading = false;
        },
        async loadTypes() {
            this.advanced.type.loading = true;
            var result = await this.$api.get("reference/list?database=jobtype", this.$user);
            var types = result.data.docs.sort((a, b) => a.value <= b.value ? -1 : 1);
            types = types.map(type => type.value);
            this.$set(this.advanced.type, "items", types);
            this.advanced.type.loading= false;
        }
    },
    components: {
        jobSearchResult
    }
}
</script>