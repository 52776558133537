<style>

</style>

<template>
    <v-container class="elevation-4" fill-height style="align-items:flex-start;position:relative;">
        <v-overlay :value="loading">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-container class="pb-10 mb-10">
            <v-row>
                <v-col class="pb-0">
                    <h2>{{ id ? "Edit" : "Add" }} a Role</h2>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="pb-0">
                    <v-text-field filled label="Role Name" v-model="role.name" persistent-hint
                        hint="Name for the role" counter="100"></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="pb-0">
                    <v-select filled label="Tag" v-model="role.tag" :items="tags" clearable persistent-hint hint="Choose an optional system tag for this role. This dictates which role is assigned during the various workflows within the system" counter="100"></v-select>
                </v-col>
            </v-row>  
            <v-row>
                <v-col class="pb-0">
                    <v-progress-linear v-if="actions.loading" indeterminate color="primary"></v-progress-linear>
                </v-col>
            </v-row>                  
            <v-row>
                <v-col class="pt-0 pb-0" cols="12" sm="4" v-for="action in actions.items" :key="action._id">
                    <v-switch v-model="role.actions" inset :label="action.description" :value="action._id" hide-details></v-switch>
                </v-col>
            </v-row>                       
        </v-container>
        <div style="position:fixed;bottom:0px;left:0px;width:100%;">
            <v-container class="pa-0">
                <v-card class="pt-4 pb-2 elevation-6">
                    <v-card-actions class="pt-0 d-flex justify-center">
                        <v-btn color="primary" @click="save" width="150" :loading="saving">
                            Save
                        </v-btn>
                        <v-btn color="secondary" @click="cancel" width="150" :disabled="saving">
                            Cancel
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-container>
        </div>
    </v-container>
</template>

<script>
import events from "../../app/app.events";

import auth from "../../mixins/auth";

export default {
    mixins: [ auth ],
    props: {
        id: {}
    },
    data: function () {
        return {
            tags: [
                "candidate",
                "partner",
                "pending"
            ],
            role: {
                name: "",
                tag: "",
                actions: [],
            },
            actions: {
                loading: false,
                items: []
            },
            loading: false,
            saving: false
        }
    },
    mounted: async function () {
        if (this.id) {
            await this.loadRole(this.id);
        }
        await this.loadActions();
    },
    created: function () {
    },
    computed: {
    },
    methods: {
        async save() {
            this.saving = true;
            await this.$api.post("role/upsert", this.role, this.$user);
            this.saving = false;

            this.$bus.emit(events.APP_SNACKBAR_MESSAGE, "Role Saved");
            setTimeout(() => {
                this.$router.push({ name: "role-list" });
            }, 1000);
        },
        cancel() {
            this.$router.push({ name: "role-list" });
        },
        async loadActions() {
            this.actions.loading = true;
            var result = await this.$api.get("role/getActions", this.$user);
            if (result) {
                var items = [];
                for (var record of result.data.rows) {
                    var action = record.doc;
                    items.push(action);
                }

                items.sort((a, b) => {
                    if (a.name < b.name) {
                        return -1;
                    }
                    if (a.name > b.name) {
                        return 1;
                    }

                    return 0;
                });

                this.$set(this.actions, "items", items);

                console.log(this.actions.items);
            }
            this.actions.loading = false;
        },
        async loadRole(id) {
            this.loading = true;
            var result = await this.$api.get("role/get?id=" + id, this.$user);
            var role = result.data;
            if (!role) {
                this.$bus.emit(events.APP_SNACKBAR_MESSAGE, "Role Not Found");
                return;
            }

            this.$set(this, "role", role);
            this.loading = false;
        },
    },
    components: {
    }
}
</script>