import { render, staticRenderFns } from "./app.router.view.vue?vue&type=template&id=c94f9e06&scoped=true&"
import script from "./app.router.view.vue?vue&type=script&lang=js&"
export * from "./app.router.view.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../common/temp/node_modules/.pnpm/vue-loader@15.10.0_68125b3067bc9264b7f5e63a32c23d52/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c94f9e06",
  null
  
)

export default component.exports