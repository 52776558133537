<style scoped>
</style>

<template>
    <v-container>
        <v-col>
            <v-row>
                <v-select :disabled="values.dialogLoading" filled hide-details v-model="types.selected" :menu-props="{ bottom: true, offsetY: true }" :loading="types.loading" :items="types.items" item-text="title" item-value="database" label="Reference Data Type" @input="selectType"></v-select>
            </v-row>
            <v-row>
                <v-data-table height="70vh" style="width:100%;" mobile-breakpoint="200" :calculate-widths="true" :headers="values.headers" :loading="values.loading" :items="values.items" class="elevation-2 mt-4" :sort-by.sync="values.sortBy" :sort-desc.sync="values.sortDesc">
                    <template v-slot:item.actions="{ item }">
                        <v-btn @click="editItem(item)" small color="primary" width="60">Edit</v-btn>
                        <v-btn @click="deleteItem(item)" small class="ml-1" color="secondary" width="60">Delete</v-btn>
                    </template>
                    <template v-slot:top>
                        <v-btn :disabled="!types.selected" small class="mt-2 ml-2" @click="addItem" color="primary">Add New</v-btn>
                    </template>
                </v-data-table>
            </v-row>
        </v-col>
        <v-dialog persistent v-model="values.dialog" max-width="500px">
            <v-card>
                <v-card-title>
                    Enter New Value
                </v-card-title>
            <v-card-text>
                <v-text-field filled hide-details label="New Value" v-model="values.newValue"></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer><v-btn color="primary" @click="saveValue" :loading="values.dialogLoading">Save</v-btn><v-btn color="secondary" @click="values.dialog = false" :disabled="values.dialogLoading">Close</v-btn>
            </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import events from "../../app/app.events";

import auth from "../../mixins/auth";

export default {
    mixins: [ auth ], 
    data: function () {
        return {
            types: {
                selected: null,
                items: [],
                loading: false
            },
            values: {
                loading: false,
                headers: [
                    { text: "Value", value: "value" },
                    { text: "Deleted", value: "deleted", width: "120" },
                    { text: "Actions", value: "actions", width: "170" }
                ],
                items: [],
                isNew: false,
                dialog: false,
                dialogLoading: false,
                selected: null,
                newValue: "",
                sortBy: "value",
                sortDesc: false,                
            }
        }
    },
    mounted: function () {
        this.loadTypes();
    },
    created: function () {
    },
    computed: {
    },
    methods: {
        async loadTypes() {
            this.types.loading = true;
            var result = await this.$api.get("reference/getTypes", this.$user);
            this.$set(this.types, "items", result.data);
            this.types.loading = false;
        },
        async selectType() {
            this.values.loading = true;
            var result = await this.$api.get("reference/list?all=true&database=" + this.types.selected, this.$user);
            this.$set(this.values, "items", result.data.docs);
            this.values.loading = false;
        },
        editItem(item) {
            this.values.selected = item;
            this.values.newValue = item.value;
            this.values.dialog = true;
        },
        addItem() {
            this.values.selected = {
                value: "",
                deleted: false
            };
            this.values.isNew = true;
            this.values.newValue = "";
            this.values.dialog = true;
            //this.values.items.push(this.)
        },
        async deleteItem(item) {
            this.values.isNew = false;
            this.values.loading = true;

            var waiting = new Promise((resolve) => {
                this.$bus.emit(events.APP_DIALOG_OPEN, { title: "Confirm Delete", message: "Are you sure you want to delete this item?", onConfirm: () => { resolve(true) }, onCancel: () => { resolve(false) } });
            });

            var okay = await waiting;
            if (okay) {
                await this.$api.delete("reference/delete?database=" + this.types.selected + "&id=" + item._id, this.$user);

                setTimeout(() => {
                    this.selectType();
                }, 500);
            }

            this.values.loading = false;
        },

        async saveValue() {
            this.values.dialogLoading = true;
            this.values.selected.value = this.values.newValue;

            var result = await this.$api.post("reference/upsert?database=" + this.types.selected, this.values.selected, this.$user);
            this.values.selected._id = result.data.id;
            this.values.selected._rev = result.data.rev;
            if (this.values.isNew) {
                this.values.items.push(this.values.selected);
            }

            this.values.dialogLoading = false;
            this.values.dialog = false;
        }
    },
    components: {
    }
}
</script>