import Vue from "vue";

Vue.mixin({
    created() {
        var that = this;
        this.$security = {
            async reissueToken() {
                var results = await that.$api.get("login/reissue", { token: that.$root.authToken });
                that.$root.authToken = results.data;
                localStorage.setItem("authToken", that.$root.authToken);            
            },
            async clearToken() {
                that.$root.authToken = null;
                localStorage.setItem("authToken", that.$root.authToken); 
            }
        }
    },    
    computed: {
        $user() {
            return this.$root.user;
        },
        $role() {
            return this.$root.role;
        }        
    },
});

export default null;