<style scoped>
</style>

<template>
    <v-container class="elevation-4" fill-height style="align-items:flex-start;position:relative;">
        <v-overlay :value="loading">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-container class="pb-10 mb-10">
            <v-row>
                <v-col class="pb-0">
                    <h2>Bulk Upload Jobs</h2>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="pb-0">
                    Please use the following XLSX template for uploading bulk jobs <a href="/SoldierOnPortalImportFormat.xlsx">Jobs Portal Import Template</a>
                </v-col>
            </v-row>            
            <v-row>
                <v-col class="pt-2 pb-4 pl-8">
                    <v-file-input filled v-model="file" label="Jobs Spreadsheet (XLSX)" persistent-hint hint="Choose a bulk job list to import in XLSX format" accept=".xlsx">
                        <template v-slot:append-outer>
                            <v-btn @click="process()">Process</v-btn>
                        </template>
                    </v-file-input>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="pb-0">
                    <h3>Processed Jobs</h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="pt-0 pb-0">
                    <v-data-table height="30vh" style="width:100%;" :calculate-widths="true" :headers="jobs.headers" :loading="loading" :items="jobs.items" class="elevation-2 mt-4" :disable-sort="true" :fixed-header="true">
                        <template v-slot:item.title="{ item }">
                            <div class="mt-2" style="font-weight:bold;">{{ item.job.title }}</div>
                        </template>
                        <template v-slot:item.postDateTicks="{ item }">
                            {{ item.job.postDateDisplay }}
                        </template>
                        <template v-slot:item.closeDateTicks="{ item }">
                            {{ item.job.closeDateDisplay }}
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-btn @click="showPreview(item)" small width="90" color="primary">Preview</v-btn><br/>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="pb-0">
                    <h3>Detected Issues</h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="pt-0 pb-0">
                    <v-data-table height="20vh" style="width:100%;" :headers="issues.headers" :loading="loading" :items="issues.items" class="elevation-2 mt-4" :disable-sort="true" :fixed-header="true"></v-data-table>
                </v-col>
            </v-row>
        </v-container>
        <v-dialog persistent v-model="preview.dialog" content-class="elevation-0">
            <v-container>
                <v-card class="elevation-6">
                    <v-card-title>
                        Job Listing Preview
                    </v-card-title>
                    <v-card-text style="max-height:70vh;overflow-y:scroll;">
                        <h3 class="pa-2">Search Result</h3>
                        <job-search-result :item="jobs.selected"></job-search-result>
                        <h3 class="pa-2 pt-4">Posting</h3>
                        <job-posting-details :item="jobs.selected"></job-posting-details>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer><v-btn color="secondary" @click="preview.dialog = false">Close</v-btn>
                    </v-card-actions>
                </v-card>
            </v-container>
        </v-dialog>
        <div style="position:fixed;bottom:0px;left:0px;width:100%;z-index:10;">
            <v-container class="pa-0">
                <v-card class="pt-4 pb-2 elevation-6">
                    <v-card-actions class="pt-0 d-flex justify-center">
                        <v-btn color="primary" @click="publish" width="150">
                            Bulk Publish
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-container>
        </div>
    </v-container>
</template>

<script>
import { read, utils } from 'xlsx'
import Job from "soldieron-data/contracts/job.mjs";
import { parse as dateParse } from "date-fns";

import jobSearchResult from "./job.search.result.vue";
import jobPostingDetails from "./job.posting.details.vue";

import events from "../../app/app.events";

class Issue {
    line;
    message;
    constructor(lineNumber, message) {
        this.line = lineNumber;
        this.message = message;
    }
}

import auth from "../../mixins/auth";

export default {
    mixins: [ auth ],
    data: function () {
        return {
            file: null,
            loading: false,
            categories: {
                items: []
            },
            types: {
                items: []
            },
            issues: {
                headers: [
                    { text: "Row", value: "line", width: "60" },
                    { text: "Message", value: "message" },
                ],
                items: []
            },
            company: null,
            jobs:{
                headers: [
                    { text: "Row", value: "row", width: "60" },
                    { text: "Job", value: "title" },
                    { text: "Posted", value: "postDateTicks", width: "130" },
                    { text: "Closing", value: "closeDateTicks", width: "130" },
                    { text: "Actions", value: "actions", width: "120" }
                ],
                items: [],
                selected: null
            },
            preview: {
                dialog: false
            }
        }
    },
    async mounted () {
        var result = await this.$api.get("company/getNameByUserId", this.$user);
        this.company = result.data;

        await this.loadCategories();
        await this.loadTypes();
    },
    created: function () {
    },
    computed: {
    },
    methods: {
        async process() {
            var issues = [];
            this.$set(this.issues, "items", issues);

            var jobs = [];
            this.$set(this.jobs, "items", jobs);

            this.jobs.loading = true;
            /* get data as an ArrayBuffer */
            const file = this.file;
            const data = await file.arrayBuffer();

            /* parse and load first worksheet */
            const wb = read(data);
            const ws = wb.Sheets[wb.SheetNames[0]];
            
            var ref = utils.sheet_to_json(ws, { header: 1 });


            for (var rowIndex = 1; rowIndex < ref.length; rowIndex++) {
                var row = ref[rowIndex];

                if (!row[2]) {
                    issues.push(new Issue(rowIndex, "Job category cannot be left blank"));
                    continue;
                }

                var category = this.categories.items.find((c) => c.value.toLowerCase() == row[2].toLowerCase());
                if (!category) {
                    issues.push(new Issue(rowIndex, "Unable to find job category '" + row[2] + "'"));
                    continue;
                }

                if (!row[3]) {
                    issues.push(new Issue(rowIndex, "Job type cannot be left blank"));
                    continue;
                }

                var type = this.types.items.find((t) => t.value.toLowerCase() == row[3].toLowerCase());
                if (!type) {
                    issues.push(new Issue(rowIndex, "Unable to find job type '" + row[3] + "'"));
                    continue;
                }

                var closeDate;

                try {
                    closeDate = dateParse(row[6], "dd/MM/yyyy", new Date());
                }
                catch {
                    closeDate = null;
                }

                if (!closeDate || closeDate == "Invalid Date") {
                    issues.push(new Issue(rowIndex, "Unable to process close date of '" + row[6] + "'"));
                    continue;
                }

                var location = await this.searchLocation(row[4], row[5]);

                if (!location) {
                    issues.push(new Issue(rowIndex, "Cannot find the specified location '" + row[4] + " " + row[5] + "'"));
                    continue;
                }

                var job = new Job();
                job.title = row[0],
                job.summary =row[1],
                job.type = type;
                job.category = category;
                job.location = location;
                job.setCloseDate(closeDate.getTime());
                job.setDescription(row[7]);
                job.company = this.company;
                job.setPostDate ((new Date()).getTime());

                jobs.push({
                    row: rowIndex,
                    job
                });
            }

            this.$set(this.jobs, "items", jobs);
            this.$set(this.issues, "items", issues);

            this.jobs.loading = false;
        },
        async loadCategories() {
            this.loading = true;
            var result = await this.$api.get("reference/list?database=jobcategory", this.$user);
            var categories = result.data.docs.sort((a, b) => a.value <= b.value ? -1 : 1);
            this.$set(this.categories, "items", categories);
            this.loading  = false;
        },
        async loadTypes() {
            this.loading = true;
            var result = await this.$api.get("reference/list?database=jobtype", this.$user);
            var types = result.data.docs.sort((a, b) => a.value <= b.value ? -1 : 1);
            this.$set(this.types, "items", types);
            this.loading = false;
        },
        showPreview(item) {
            this.jobs.selected = item.job;
            this.preview.dialog = true;
        },
        async publish() {
            if (this.jobs.items.length == 0) {
                this.$bus.emit(events.APP_SNACKBAR_MESSAGE, "Please process an input file before publishing");                
                return;
            }

            this.loading = true;
            for (var item of this.jobs.items) {
                await this.$api.post("job/upsert", item.job, this.$user);
            }
            this.loading = false;

            this.$bus.emit(events.APP_SNACKBAR_MESSAGE, "Bulk Job Publishing Complete");
            
        },
        async searchLocation(findState, findSuburb) {
            if (!findState || findState.length == 0 || !findSuburb || findSuburb.length == 0) {
                return null;
            }
            var q = "state:" + findState + " AND suburb:\"" + findSuburb + "\"~";                                

            var suburb = null;
            var result = await this.$api.get("suburb/search?q=" + q, this.$user);
            if (result && result.data.length > 0) {
                var records = result.data;
                if (records.length == 0) {
                    return null;
                }
                
                suburb = records[0]._source;
                suburb.id = records[0]._id;
                suburb.display = suburb.suburb + " " + suburb.state + " " + suburb.postcode;
            }
            return suburb;
        }
    },
    components: {
        jobSearchResult,
        jobPostingDetails,
    }
}
</script>