<style>
    .v-text-field .v-input__append-outer {
        margin-top:9px !important;
    }

    .v-file-input.icon-right {
        flex-direction: row-reverse;
    }

    .v-text-field--outlined.icon-right .v-input__prepend-outer {
        margin-left:6px !important;
    }

    .record-container {
        position: relative;
        display: flex;
        justify-content: center;
    }
</style>

<template>
    <v-container class="elevation-4" fill-height style="align-items:flex-start;position:relative;">
        <v-overlay :value="loading">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-container class="pb-14">
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-row>
                    <v-col class="pb-0">
                        <h2>Resume</h2>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pb-0">
                        Please upload your resume. Once uploaded it will be processed and made searchable, you will also be able to use the skills suggestion feature below.
                    </v-col>
                </v-row>                
                <v-row>
                    <v-col class="pb-0">
                        <div v-if="hasResume">
                            <a @click="download(profile)">{{resume.filename}}</a>
                            <v-btn class="ml-4" small depressed @click="changeResume">Change</v-btn>
                        </div>
                        <v-file-input v-else filled v-model="file" label="Resume" persistent-hint hint="Choose a resume to upload in PDF or DOCX format">
                            <template v-slot:append-outer>
                                <v-btn @click="save(false)">Upload</v-btn>
                            </template>
                        </v-file-input>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pb-0">
                        <h2>Skills</h2>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pb-0">
                        The skills you select below will be used for making sugeestions to you about which posted jobs may be suitable for your skillset. They will also be used to suggest you as a potential applicant to companies using the job posting match service.
                    </v-col>
                </v-row>                
                <v-row>
                    <v-col class="pt-0">
                        <v-autocomplete class="mt-4" v-model="profile.skills" :items="allSkills" small-chips deletable-chips label="Skills" multiple outlined menu-props="closeOnContentClick" clearable></v-autocomplete>
                    </v-col>
                </v-row>                
                <v-row>
                    <v-col class="pt-0" style="text-align:center;">
                        <v-btn @click="getSuggestions" :loading="skillsLoading">Suggest Skills Based on Resume</v-btn>
                    </v-col>                    
                </v-row>                
            </v-form>        
        </v-container>        
        <div style="position:fixed;bottom:0px;left:0px;width:100%;">
            <v-container class="pa-0">
                <v-card class="pt-4 pb-2 elevation-6">
                    <v-card-actions class="pt-0 d-flex justify-center">
                        <v-btn color="primary" @click="save(true)" width="150" :loading="saving">
                            Save
                        </v-btn>
                        <v-btn color="secondary" @click="cancel" width="150" :disabled="saving">
                            Cancel
                        </v-btn>                        
                    </v-card-actions>
                </v-card>
            </v-container>
        </div>
    </v-container>
</template>

<script>
import tools from "../../util/tools.utils";
import events from "../../app/app.events";

import Profile from "soldieron-data/contracts/profile.mjs";

import nlp from "compromise";
import skills from "../skills/skills.json";


import auth from "../../mixins/auth";

export default {
    mixins: [ auth ],
    data: function () {
        return {
            file: null,
            profile: new Profile({}),
            resume: null,
            resumeBackup: null,
            hasResume: false,
            loading: false,
            saving: false,
            valid: true,
            new: true,
            skillsLoading: false,
            allSkills: skills,            
        }
    },
    mounted: function () {
        this.loadProfile();
        this.loadSkills();
    },
    created: function () {
    },
    computed: {
    },
    methods: {
        changeResume() {
            this.hasResume = false;
            this.resumeBackup = this.resume;
            this.resume = null;
        },
        async loadSkills() {
            nlp.compile(skills);
        },        
        removeSkill(skill) {
            this.skills.splice(this.skills.indexOf(skill), 1);
        },
        async getSuggestions() {
            this.skillsLoading = true;
            // load resume text
            var result = await this.$api.get("profile/getFromIndex?id=" + this.profile._id, this.$user);
            if (result) {
                var doc = result.data._source
                if (!doc.attachment) {
                    this.$bus.emit(events.APP_SNACKBAR_MESSAGE, "No resume content found!");
                    this.skillsLoading = false;
                    return;
                }

                var foundSkills = this.getSkills(doc);
                foundSkills = foundSkills.concat(this.profile.skills);
                var uniqueSkills = [...new Set(foundSkills)];
                this.$set(this.profile, "skills", uniqueSkills);
            }
            this.skillsLoading = false;
        },
        getSkills(record) {
            if (record) {
                var doc = nlp(record.attachment.content);
                var foundSkills = doc.lookup(skills).normalize().unique();
                var skillsList = foundSkills.toLowerCase().toTitleCase().docs.map(match => match.map(term => term.pre + term.text + term.post).join("").trim());

                var matchedList = [];
                for (var skill of skillsList) {
                    if (skills.includes(skill)) {
                        matchedList.push(skill);
                    }
                }

                return matchedList;
            }
            else {
                return [];
            }
        },          
        async loadProfile() {
            this.loading = true;
            var result = await this.$api.get("profile/getMine", this.$user);
            if (result.data) {
                this.processProfile(result.data);
            }
            this.loading = false;
        },
        processProfile(data) {
            var profileData = data;
            this.profile = new Profile(profileData);

            this.hasResume = (!!this.profile._attachments);

            if (this.hasResume) {
                this.resume = Object.values(this.profile._attachments)[0];
                this.resume.filename = Object.keys(this.profile._attachments)[0];
            }

            this.new = false;
        },
        async save(redirect) {
            if (!this.file && !this.resume) {
                this.$bus.emit(events.APP_SNACKBAR_MESSAGE, "Please select a file to upload");
                return; 
            }

            var encoded;

            this.saving = true;
            if (this.file) {
                encoded = await tools.fileDataSerialize(this.file);
            }

            this.profile._id = this.$user.id
            this.profile.file = this.file ? {
                filename: this.file.name,
                data: encoded
            } : null

            console.log(this.profile);

            var result = await this.$api.post("profile/upsert", this.profile, this.$user);
            if (result.data) {
                this.processProfile(result.data);
            }

            this.saving = false;

            this.$bus.emit(events.APP_SNACKBAR_MESSAGE, "Resume and Skills Updated");
            if (redirect) {
                setTimeout(() => {
                    this.$router.push({ name: "profile-overview" });
                }, 1000);
            }
        },
        async download(profile) {
            var result = await this.$api.get("profile/downloadFile?profileId=" + profile._id, this.$user);
            var record = result.data;

            if (record) {
                var filename = Object.keys(record)[0];
                var details = record[filename];
                var source = "data:" + details.content_type + ";base64," + details.data;
                var anchor = document.createElement("a");
                
                anchor.href = source;
                anchor.download = filename;
                anchor.click();
            }
        },
        cancel() {
            this.$router.push({ name: "profile-overview" });
        },   
    },
    components: {
    }
}
</script>