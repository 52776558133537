<style>
</style>

<template>
    <v-container class="elevation-4" fill-height style="align-items:flex-start;position:relative;">
        <v-container class="pb-14">
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-row>
                    <v-col class="pb-0">
                        <h2>Job Preferences</h2>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pb-0">
                        Your job preferences will be taken into account when you use the job matching capability of the job search to find positions that the system thinks might be suitable for you.<br/><br/>
                        They will also be taken into account when a company chooses to search for potential candidates for a position they are advertising.<br/><br/>
                        Selecting no categories or types will mean that <b>all</b> jobs will match your preferences.
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-combobox filled multiple label="Preferred Job Categories" v-model="profile.preferred.categories" :items="category.items" clearable persistent-hint hint="Select one or more job categories that you prefer to work in"></v-combobox>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-combobox filled multiple label="Preferred Job Types" v-model="profile.preferred.types" :items="type.items" clearable persistent-hint hint="Select one or more preferred job types"></v-combobox>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pb-0">
                        <h3>Location</h3>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="4">
                        <v-select filled v-model="profile.preferred.within" label="Within" :items="within.items" item-text="display" item-value="distance" persistent-hint hint="Select a search radius">
                            <template v-slot:append-outer> 
                                <div class="mt-1 ml-2">of</div>
                            </template>
                        </v-select>
                    </v-col>
                    <v-col cols="12" sm="8">
                        <v-autocomplete filled v-model="profile.preferred.location" :items="location.items" :loading="location.loading" :search-input.sync="location.search" item-text="display" item-value="id" label="Location" placeholder="Start typing to Search" append-icon="mdi-map-marker" return-object no-filter clearable hide-no-data></v-autocomplete>
                    </v-col>
                </v-row>
            </v-form>
        </v-container>
        <div style="position:fixed;bottom:0px;left:0px;width:100%;">
            <v-container class="pa-0">
                <v-card class="pt-4 pb-2 elevation-6">
                    <v-card-actions class="pt-0 d-flex justify-center">
                        <v-btn color="primary" @click="save" width="150" :loading="saving">
                            Save
                        </v-btn>
                        <v-btn color="secondary" @click="cancel" width="150" :disabled="saving">
                            Cancel
                        </v-btn>                        
                    </v-card-actions>
                </v-card>
            </v-container>
        </div>
    </v-container>
</template>

<script>
import events from "../../app/app.events";

import Profile from "soldieron-data/contracts/profile.mjs";

//import allSkills from "../skills/skills.autocomplete.json";

import auth from "../../mixins/auth";

export default {
    mixins: [ auth ],
    data: function () {
        return {
            category: {
                loading: false,
                items:[]
            },
            type: {
                loading: false,
                items:[]
            },
            location: {
                loading: false,
                items: [],
                search: ""
            },
            within: {
                items: [
                    { distance: 10, display: "10kms" },
                    { distance: 25, display: "25kms" },
                    { distance: 50, display: "50kms" },
                    { distance: 100, display: "100kms" }
                ]
            },            
            profile: new Profile({}),
            loading: false,
            saving: false,
            valid: true,
            new: true
        }
    },
    mounted: function () {
        this.loadProfile();
        this.loadCategories();
        this.loadTypes();
    },
    created: function () {
    },
    computed: {
    },
    watch: {
        async "location.search"(val) {
            await this.searchLocation(val);
        },
    },
    methods: {
        async loadProfile() {
            var result = await this.$api.get("profile/getMine", this.$user);
            if (result.data) {
                var profileData = result.data;
                this.profile = new Profile(profileData);
                this.new = false;

                if (this.profile.preferred.location) {
                    this.location.items = [this.profile.preferred.location];                
                }
            }
        },
        async searchLocation(val, bypass) {
            if (val && val.length > 2) { // search on 3 or more characters
                if (this.profile.preferred.location && val == this.profile.preferred.location.display && !bypass) {
                    return;
                }
                                
                this.$set(this.location, "items", []);

                this.location.loading = true;

                var result = await this.$api.get("suburb/search?q=*" + val.split(" ").join("?") + "*", this.$user);
                if (result && result.data.length > 0) {
                    var items = [];
                    for (var record of result.data) {
                        var suburb = record._source;
                        suburb.id = record._id;
                        suburb.display = suburb.suburb + " " + suburb.state + " " + suburb.postcode;
                        items.push(Object.assign({}, suburb));
                    }
                    this.$set(this.location, "items", items);
                    console.log(this.location.items);
                }
                else {
                    this.$set(this.location, "items", []);
                }

                this.location.loading = false;
            }
            else {
                    this.$set(this.location, "items", []);
            }
        },
        async save() {
            if (!this.$refs.form.validate()) {
                return;
            }

            if (this.new) {
                this.profile._id = this.$user.id
            }

            this.saving = true;
            await this.$api.post("profile/upsert", this.profile, this.$user);
            this.saving = false;

            this.$bus.emit(events.APP_SNACKBAR_MESSAGE, "Profile Saved");
            setTimeout(() => {
                this.$router.push({ name: "profile-overview" });
            }, 1000);
        },
        cancel() {
            this.$router.push({ name: "profile-overview" });
        },
        async loadCategories() {
            this.category.loading = true;
            var result = await this.$api.get("reference/list?database=jobcategory", this.$user);
            var categories = result.data.docs.sort((a, b) => a.value <= b.value ? -1 : 1);
            categories = categories.map(category => category.value);
            this.$set(this.category, "items", categories);
            this.category.loading = false;
        },
        async loadTypes() {
            this.type.loading = true;
            var result = await this.$api.get("reference/list?database=jobtype", this.$user);
            var types = result.data.docs.sort((a, b) => a.value <= b.value ? -1 : 1);
            types = types.map(type => type.value);
            this.$set(this.type, "items", types);
            this.type.loading= false;
        }             
    },
    components: {
    }
}
</script>