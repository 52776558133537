class Profile {
    _id;
    _rev;
    _attachments;
    firstName;
    lastName;
    phoneNumber;
    about;
    aboutText;
    preferred;
    skills;
    officer;

    constructor(profile) {
        this._id = profile._id
        this._rev = profile._rev;
        this._attachments = profile._attachments;

        this.firstName = profile.firstName;
        this.lastName = profile.lastName;
        this.phoneNumber = profile.phoneNumber;
        this.about = profile.about;
        this.aboutText = profile.aboutText;
        this.skills = profile.skills;

        this.officer = profile.officer;

        if (!profile.preferred) {
            this.preferred = {
                categories: [],
                types: [],
                location: {display: null},
                within: 0
            }
        }
        else {
            this.preferred = profile.preferred;
        }
    }

    getFullName() {
        return this.firstName + " " + this.lastName;
    }

    validateName(name) {
        if (!name) {
            return "Name is a required field";
        }

        if (name.length < 2) {
            return "Name must be at least 2 characters long";
        }

        if (name.length > 100) {
            return "Name must be less than 100 characters";
        }

        return true;
    }

    validatePhoneNumber(phoneNumber) {
        return true;
    }

    validateObject() {
        return this.validateName(this.firstName) && this.validateName(this.lastName);
    }
}

export default Profile;