import dateFns from "date-fns";
import showdown from "showdown";

var converter = new showdown.Converter();

class Job {
    _id;
    _rev;
    title;
    summary;
    category;
    type;
    location;
    preferredSkills;
    requiredSkills;
    company;
    
    description;
    descriptionText;
    descriptionMarkdown;

    setDescription(markdown) {
        this.descriptionMarkdown = markdown;
        this.description = converter.makeHtml(markdown);
        this.descriptionText = markdown;
    }

    postDate;
    postDateDisplay;    
    postDateTicks;

    setPostDate(ticks) {
        this.postDateTicks = ticks;
        if (ticks == 0) {
            this.postDate = "";
            this.postDateDisplay = "";
            return;
        }

        var tickDate = new Date(ticks);
        this.postDate = dateFns.format(tickDate, "yyyy-MM-dd");
        this.postDateDisplay = dateFns.format(tickDate, "dd/MM/yyyy");
    }

    closeDate;
    closeDateDisplay;
    closeDateTicks;

    setCloseDate(ticks) {
        this.closeDateTicks = ticks;
        if (ticks == 0) {
            this.closeDate = "";
            this.closeDateDisplay = "";
            return;
        }

        var tickDate = new Date(ticks);
        this.closeDate = dateFns.format(tickDate, "yyyy-MM-dd");
        this.closeDateDisplay = dateFns.format(tickDate, "dd/MM/yyyy");
    }

    constructor(job) {
        if (!job) {
            job = {};
        }
        this._id = job._id;
        this._rev = job._rev;
        this.title = job.title ? job.title : "";
        this.summary = job.summary ? job.summary : "";
        this.category = job.category ? job.category : null;
        this.type = job.type ? job.type : null;
        this.location = job.location ? job.location : null;
        this.preferredSkills = job.preferredSkills ? job.preferredSkills : [];
        this.requiredSkills = job.requiredSkills ? job.requiredSkills : [];
        this.company = job.company ? job.company : null;
        this.setDescription(job.descriptionMarkdown ? job.descriptionMarkdown : "");
        this.setCloseDate(job.closeDateTicks ? job.closeDateTicks : 0);
        this.setPostDate(job.postDateTicks ? job.postDateTicks : 0);
    }

    validateObject() {
        return true;
    }
}

export default Job;