<style scoped>
</style>

<template>
    <v-container class="elevation-4" fill-height style="align-items:flex-start;position:relative;">
        <v-overlay :value="loading">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-container class="pb-14">
            <job-posting-details v-if="job" :item="job" :short="true"></job-posting-details>

            <v-data-table height="25vh" style="width:100%;" :calculate-widths="true" :headers="applicant.headers" :loading="loading" :items="applicant.items" class="elevation-2 mt-4">
                <template v-slot:item.firstName="{ item }">
                    <div class="mt-2" style="font-weight:bold;">{{ item.firstName }} {{ item.lastName }}</div>
                    <div class="mt-1 mb-2">{{ item.summary }}</div>
                </template>
                <template v-slot:item.matchedSkills="{ item }">
                    Required {{ getSkillsMatch(item, "requiredSkills").length }}<br/>
                    Preferred {{ getSkillsMatch(item, "preferredSkills").length }}
                </template>                
                <template v-slot:item.applyDate="{ item }">
                    {{ formatDate(job.applicants[item.id]) }}
                </template>                
                <template v-slot:item.actions="{ item }">
                    <v-btn @click="viewProfile(item)" small color="primary">View</v-btn>
                </template>
            </v-data-table>


            <v-data-table height="25vh" style="width:100%;" :calculate-widths="true" :headers="potential.headers" :loading="loading" :items="potential.items" class="elevation-2 mt-4" show-select v-model="potential.selected" item-key="_id">
                <template v-slot:item.firstName="{ item }">
                    <div class="mt-2" style="font-weight:bold;">{{ item.firstName }} {{ item.lastName }}</div>
                    <div class="mt-1 mb-2">{{ item.summary }}</div>
                </template>
                <template v-slot:item.matchedSkills="{ item }">
                    Required {{ getSkillsMatch(item, "requiredSkills").length }}<br/>
                    Preferred {{ getSkillsMatch(item, "preferredSkills").length }}
                </template>                
                <template v-slot:top>
                    <v-btn :disabled="loading" small class="mt-2 ml-4" @click="findCandidates" color="primary">Find Potential Candidates</v-btn>
                </template>
                <template v-slot:footer.prepend>
                    <v-btn :disabled="loading" small class="mt-0 ml-2" @click="markPreferred" color="secondary">Mark as Preferred</v-btn>
                </template>
            </v-data-table>

        </v-container>
        <div style="position:fixed;bottom:0px;left:0px;width:100%;">
            <v-container class="pb-0">
                <v-card class="pt-2 elevation-6">
                    <v-card-actions class="pt-0 d-flex justify-center">
                        <v-btn color="secondary" @click="cancel" width="150">
                            Cancel
                        </v-btn>                        
                    </v-card-actions>
                </v-card>
            </v-container>
        </div>
    </v-container>
</template>

<script>
import jobPostingDetails from './job.posting.details.vue';

import events from "../../app/app.events";

import dateUtils from "../../util/date.utils";

import auth from "../../mixins/auth";

export default {
    mixins: [ auth ],
    props: {
        id: {},
        item: {}
    },
    data: function () {
        return {
            applying: false,
            loading: false,
            posting: null,
            publishing: false,
            applicant: {
                items: [],
                headers: [
                    { text: "Applicant", value: "firstName" },
                    { text: "Officer", value: "officer.name", width: "180" },
                    { text: "Matched Skills", value: "matchedSkills", width: "140" },
                    { text: "Applied On", value: "applyDate", width: "120" },
                    { text: "Actions", value: "actions", width: "100" }
                ],
            },
            potential: {
                items: [],
                selected: [],
                headers: [
                    { text: "Applicant", value: "initials" },
                    { text: "Officer", value: "officer", width: "180" },
                    { text: "Matched Skills", value: "matchedSkills", width: "140" },
                ]
            },

        }
    },
    async mounted() {
        if (this.id && !this.item) {
            await this.loadPosting(this.id);
        }

        await this.getApplicants(this.id);

        if (this.job.potentialCandidates) {
            this.potential.items = this.job.potentialCandidates;
            this.potential.selected = this.job.potentialCandidates;
        }
    },
    created: function () {
    },
    computed: {
        job() {
            if (this.item) {
                return this.item; 
            }

            return this.posting;
        }
    },
    methods: {
        formatDate(ticks) {
            return dateUtils.formatDateShort(ticks);
        },
        getSkillsMatch(item, type) {
            var matches = item.skills.filter(s => this.job[type].includes(s));
            return matches;
        },
        async findCandidates() {
            var result = await this.$api.get("job/findPotentialCandidates?id=" + this.id, this.$user);
            this.$set(this.potential, "items", result.data);
            console.log(result);
        },
        async getApplicants(id) {
            this.loading = true;
            var result = await this.$api.get("job/getApplicants?id=" + id, this.$user);
            if (result && result.data && result.data.docs) {
                var items = [];
                for (var record of result.data.docs) {
                    var profile = record._source;
                    profile.id = record._id;
                    items.push(profile);
                }               
                this.$set(this.applicant, "items", items);
            }

            this.loading = false;
        },
        viewProfile(item) {
            this.$router.push({ name: "profile-view", params: { id: item.id }});
        },
        markPreferred() {
            var job = Object.assign({}, this.job);
            job.potentialCandidates =  this.potential.selected;
            if (job.id) {
                job._id = job.id;
                delete job.id;
            }
            this.publish(job);
        },  
        async publish(job) {
            this.publishing = true;
            await this.$api.post("job/upsert", job, this.$user);
            this.publishing = false;

            this.$bus.emit(events.APP_SNACKBAR_MESSAGE, "Preferred Candidates Saved");
        },
        async loadPosting(id) {
            this.loading = true;
            var result = await this.$api.get("job/get?id=" + id, this.$user);
            var posting = result.data;
            if (!posting) {
                this.$bus.emit(events.APP_SNACKBAR_MESSAGE, "Job Posting Not Found");
                return;
            }

            this.$set(this, "posting", posting);

            console.log(this.posting);
            this.loading = false;
        },        
        cancel() {
            this.$router.go(-1);
        }
    },
    components: {
        jobPostingDetails
    }
}
</script>