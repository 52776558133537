<style>

</style>

<template>
    <v-container class="elevation-4" fill-height style="align-items:flex-start;position:relative;">
        <v-container>
            <v-col>
                <v-row>
                    <h2>Manage Roles</h2>
                </v-row>
                <v-row>
                    <v-data-table height="70vh" style="width:100%;" :calculate-widths="true" :headers="headers" :loading="loading" :items="items" class="elevation-2 mt-4" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
                        <template v-slot:item.name="{ item }">
                            <div class="mt-2 mb-1">Role Name: {{item.name}}</div>
                            <div class="mb-2" style="line-height:28px;"><v-chip v-for="action in item.actions" :key="action" small class="mr-1">{{ action }}</v-chip></div>                            
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-btn @click="editRole(item)" small width="90" color="primary">Edit</v-btn><br/>
                            <v-btn @click="deleteRole(item)" small class="mt-1" width="90" color="secondary">Delete</v-btn>
                        </template>
                        <template v-slot:top>
                            <v-btn :disabled="loading" small class="mt-2 ml-2" @click="addRole" color="primary">Add New Role</v-btn>
                        </template>
                    </v-data-table>
                </v-row>
            </v-col>
        </v-container>
    </v-container>
</template>

<script>
import events from "../../app/app.events";

import auth from "../../mixins/auth";

export default {
    mixins: [ auth ],
    props: {
        id: {}
    },
    data: function () {
        return {
            searchText: "",
            loading: false,
            headers: [
                { text: "Role Details", value: "name" },
                { text: "Role Tag", value: "tag", width: "120" },
                { text: "Actions", value: "actions", width: "170" }
            ],
            items: [],
            sortBy: "value",
            sortDesc: false,
        }
    },
    mounted: async function () {
        await this.loadRoles("*");
    },
    created: function () {
    },
    computed: {
    },
    watch: {
        show(val) {
            console.log(val);
            if (!val) {
                this.searchText = "",
                    this.$set(this, "users", []);
            }
        }
    },
    methods: {
        addRole() {
            this.$router.push({ name: "role-details" });
        },
        editRole(item) {
            this.$router.push({ name: "role-details", params: { id: item.id } });
        },
        async deleteRole(item) {
            var waiting = new Promise((resolve) => {
                this.$bus.emit(events.APP_DIALOG_OPEN, { title: "Confirm Role Delete", message: "Are you sure you want to delete this role?", onConfirm: () => { resolve(true) }, onCancel: () => { resolve(false) } });
            });

            var okay = await waiting;
            if (okay) {
                await this.$api.delete("role/delete?id=" + item.id, this.$user);
                this.$bus.emit(events.APP_SNACKBAR_MESSAGE, "Role Deleted");

                setTimeout(() => {
                    this.loadRoles("*");
                }, 1000);
            }
        },
        async loadRoles(q) {
            this.loading = true;
            var result = await this.$api.get("role/search?q=" + q, this.$user);
            console.log(result);
            if (result) {
                var items = [];
                for (var record of result.data) {
                    var company = record._source;
                    company.id = record._id;
                    items.push(company);
                }

                this.$set(this, "items", items);
            }
            this.loading = false;
        }
    },
    components: {
    }
}
</script>