import appLayout from "@/app/app.layout.vue";
import appRouterView from "@/app/app.router.view";

import loginLayout from "@/domains/login/login.layout.vue";
import loginCheck from "@/domains/login/login.check.vue";
import logout from "@/domains/login/logout.vue";

import comingSoon from "@/app/components/app.coming.soon.vue";

import home from "@/domains/home/home.vue";
import welcome from "@/domains/home/welcome.vue";

import profileOverview from "@/domains/profile/profile.overview.vue";
import profilePersonalDetails from "@/domains/profile/profile.personal.details.vue";
import profileAbout from "@/domains/profile/profile.about.vue";
import profileJobPreferences from "@/domains/profile/profile.job.preferences.vue";
import profileResumeSkills from "@/domains/profile/profile.resume.skills.vue";
import profileView from "@/domains/profile/profile.view.vue";

import reference from "@/domains/reference/reference.vue";

import jobPosting from "@/domains/job/job.posting.vue";
import jobDetails from "@/domains/job/job.posting.view.vue"
import jobList from "@/domains/job/job.list.vue"
import jobBulk from "@/domains/job/job.bulk.vue"
import jobApplicants from "@/domains/job/job.applicants.vue"

import companyList from "@/domains/company/company.list.vue";
import companyDetails from "@/domains/company/company.details.vue";

import roleList from "@/domains/role/role.list.vue";
import roleDetails from "@/domains/role/role.details.vue";

import searchProfiles from "@/domains/search/search.profiles.vue";
import searchJobs from "@/domains/search/search.jobs.vue";

import userList from '@/domains/user/user.list.vue'

import taskList from "@/domains/task/task.list.vue";

const routes = [
    {
        path: "", name: "app", component: appLayout, meta: { title: "App Layout", icon: "mdi-room-service" },
        children: [
            { path: "/", name: "root", component: comingSoon, redirect: "/home", meta: { title: "Home", icon: "mdi-home", hide: true } },
            { path: "/home", name: "home", component: home, meta: { title: "Home", icon: "mdi-home" } },
            { path: "/welcome", name: "welcome", component: welcome, meta: { title: "Welcome", icon: "", hide: true }, props: true },
            {
                path: "/profile", name: "profile", component: appRouterView, redirect: { name: "profile-overview" }, meta: { title: "My Profile", icon: "mdi-account", auth: [ "profile-menu" ] },
                children: [
                    { path: "/profile/overview", name: "profile-overview", component: profileOverview, meta: { title: "Overview", icon: "mdi-account-outline", auth: [ "profile-add-edit" ] }, props: true },
                    { path: "/profile/view/:id", name: "profile-view", component: profileView, meta: { title: "View", icon: "mdi-account-outline", hide: true, auth: [ "profile-get" ] }, props: true },
                    { path: "/profile/details/:type?", name: "profile-personal-details", component: profilePersonalDetails, meta: { title: "Personal Details", icon: "mdi-account-outline", auth: [ "profile-add-edit" ] }, props: true },
                    { path: "/profile/about", name: "profile-about-me", component: profileAbout, meta: { title: "About Me", icon: "mdi-account-outline", auth: [ "profile-add-edit" ] } },
                    { path: "/profile/preferences", name: "profile-job-preferences", component: profileJobPreferences, meta: { title: "Job Preferences", icon: "mdi-briefcase-account", auth: [ "profile-add-edit" ] } },
                    { path: "/profile/resume", name: "profile-resume", component: profileResumeSkills, meta: { title: "Resume and Skills", icon: "mdi-book", auth: [ "profile-add-edit" ] } }
                ]
            },
            { path: "/search", name: "search-jobs", component: searchJobs, meta: { title: "Search", icon: "mdi-briefcase-search", auth: [ "job-search" ] } },
            {
                path: "/pathways", name: "pathways", component: appRouterView, redirect: { name: "task-list" }, meta: { title: "Pathways", icon: "mdi-road-variant" },
                children: [
                    { path: "/pathways/tasks", name: "task-list", component: taskList, meta: { title: "Tasks", icon: "mdi-clipboard-list-outline", auth: [ "task-list" ] } },
                    { path: "/pathways/profiles", name: "search-profiles", component: searchProfiles, meta: { title: "Profile Search", icon: "mdi-account-search", auth: [ "profile-search" ] } },
                ]
            },
            {
                path: "/job", name: "job", component: appRouterView, redirect: { name: "job-list" }, meta: { title: "Partners", icon: "mdi-briefcase", auth: [ "job-search" ] },
                children: [
                    { path: "/job/posting/:id?", name: "job-posting", component: jobPosting, meta: { title: "New Posting", icon: "mdi-briefcase-plus", hide: true, auth: [ "job-add-edit", "job-get" ] }, props: true },
                    { path: "/job/details/:id?", name: "job-details", component: jobDetails, meta: { title: "Job Details", icon: "mdi-briefcase-outline", hide: true, auth: [ "job-get" ] }, props: true },
                    { path: "/job/list", name: "job-list", component: jobList, meta: { title: "Jobs", icon: "mdi-briefcase-outline", auth: [ "job-list" ] } },
                    { path: "/job/bulk", name: "job-bulk", component: jobBulk, meta: { title: "Bulk Import", icon: "mdi-briefcase-arrow-left-right", auth: [ "job-add-edit", "job-get" ] } },
                    { path: "/job/applicants/:id?", name: "job-applicants", component: jobApplicants, meta: { title: "Job Applicants", icon: "mdi-briefcase-account", hide: true, auth: [ "job-applicants" ] }, props: true },
                ]
            },
            {
                path: "/admin", name: "admin", component: appRouterView, redirect: { name: "company-list" }, meta: { title: "Admin", icon: "mdi-application-settings-outline" },
                children: [
                    { path: "/admin/user/search", name: "search-users", component: userList, meta: { title: "Users", icon: "mdi-briefcase-search", auth: [ "user-search" ] } },
                    { path: "/admin/role/list", name: "role-list", component: roleList, meta: { title: "Roles", icon: "mdi-view-list-outline", auth: [ "role-list", "role-search" ] } },
                    { path: "/admin/role/details/:id?", name: "role-details", component: roleDetails, meta: { title: "New Role", icon: "mdi-logout", hide: true, auth: [ "role-add-edit", "role-view", "role-get-actions" ] }, props: true },
                    { path: "/admin/company/list", name: "company-list", component: companyList, meta: { title: "Companies", icon: "mdi-view-list-outline", auth: [ "company-search" ] } },
                    { path: "/admin/company/details/:id?", name: "company-details", component: companyDetails, meta: { title: "New Company", icon: "mdi-logout", hide: true, auth: [ "company-add-edit" ] }, props: true },
                    { path: "/admin/reference", name: "reference-manage", component: reference, meta: { title: "Reference Data", icon: "mdi-application-cog-outline", auth: [ "reference-list", "reference-add-edit" ] } },
                ]
            },
            { path: "/account/logout", name: "account-logout", component: logout, meta: { title: "Logout", icon: "mdi-logout" } },
        ]
    },
    {
        path: "/login", name: "login", component: loginLayout, meta: { title: "Login", icon: "mdi-room-service" },
        children: [
            { path: "/login/check", name: "login-check", component: loginCheck, meta: { title: "Login Check", icon: "mdi-lightbulb" } },
        ]
    },

]

export default routes;