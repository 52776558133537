<style>
    .profile-sidebar {
        position: absolute;
        z-index: 1;
        right: 103%;
        top: 64px;
        width: 190px;
    }

    .markdown-render ul {
        margin-bottom: 16px;
    }
</style>

<template>
    <v-container class="elevation-4" fill-height style="align-items:flex-start;position:relative;">
        <v-overlay :value="loading">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-container>
            <div class="profile-sidebar" v-if="(!$vuetify.breakpoint.mdAndDown && !readOnly)">
                <v-card elevation="8" class="pa-4">
                    <b>Edit Details</b>
                    <v-container>
                        <v-row>
                            <v-col class="pa-0 pt-2">
                                <v-btn block small @click="editDetails"><b>Personal Details</b></v-btn> 
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="pa-0 pt-2">
                                <v-btn block small @click="editAbout"><b>About Me</b></v-btn> 
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="pa-0 pt-2">
                                <v-btn block small @click="editJobPreferences"><b>Job Preferences</b></v-btn> 
                            </v-col>
                        </v-row>                        
                        <v-row>
                            <v-col class="pa-0 pt-2">
                                <v-btn block small @click="editResumeAndSkills"><b>Resume and Skills</b></v-btn> 
                            </v-col>
                        </v-row>                        
                    </v-container>
                </v-card>
            </div>

            <v-row>
                <v-col class="pb-0">
                    <h2>My Profile</h2>
                </v-col>
            </v-row>
            <v-row v-if="profile.officer">
                <v-col class="pb-0">
                    <h4>Assigned Pathways Officer: {{ profile.officer.name }}</h4>                    
                </v-col>
            </v-row>
            <v-row>
                <v-col class="pb-0" v-if="!readOnly">
                    <v-btn text @click="editDetails"><b>Personal Details</b>&nbsp;&nbsp;&nbsp;<v-icon>mdi-playlist-edit</v-icon></v-btn>                    
                </v-col>
                <v-col class="pb-0" v-else>
                    <b>Personal Details</b>                   
                </v-col>                
            </v-row>
            <v-row>
                <v-col class="pt-2 pb-0 pl-8">
                    <person-avatar size="36" :avatar="$user.avatar" :name="$user.name"></person-avatar>
                    <div style="display:inline-block;vertical-align:middle;" class="ml-2">{{ profile.firstName }} {{ profile.lastName }} ({{ $user.email }})</div>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="pb-0" v-if="!readOnly">
                    <v-btn text @click="editAbout"><b>About Me</b>&nbsp;&nbsp;&nbsp;<v-icon>mdi-playlist-edit</v-icon></v-btn>
                </v-col>
                <v-col class="pb-0" v-else>
                    <b>About Me</b>                   
                </v-col>                
            </v-row>
            <v-row>
                <v-col class="pt-2 pb-0 pl-8">
                    <div v-html="profile.about" class="markdown-render"></div>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="pb-0" v-if="!readOnly">
                    <v-btn text @click="editJobPreferences"><b>Job Preferences</b>&nbsp;&nbsp;&nbsp;<v-icon>mdi-playlist-edit</v-icon></v-btn>
                </v-col>
                <v-col class="pb-0" v-else>
                    <b>Job Preferences</b>                   
                </v-col>                
            </v-row>
            <v-row>
                <v-col class="pt-2 pb-0 pl-8" v-if="profile.preferred">
                    Preferred Categories
                </v-col>
            </v-row>
            <v-row>
                <v-col class="pt-2 pb-0 pl-8" v-if="profile.preferred">
                    <v-chip small class="ma-1" v-for="(category, i) in profile.preferred.categories" :key="i">{{category}}</v-chip>
                </v-col>
            </v-row>            
            <v-row>
                <v-col class="pt-2 pb-0 pl-8" v-if="profile.preferred">
                    Preferred Types
                </v-col>
            </v-row>
            <v-row>
                <v-col class="pt-2 pb-0 pl-8" v-if="profile.preferred">
                    <v-chip small class="ma-1" v-for="(type, i) in profile.preferred.types" :key="i">{{type}}</v-chip>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="pt-2 pb-0 pl-8" v-if="profile.preferred">
                    Within {{ profile.preferred.within }}kms of {{ profile.preferred.location?.display }}
                </v-col>
            </v-row>
            <v-row>
                <v-col class="pb-0" v-if="!readOnly">
                    <v-btn text @click="editResumeAndSkills"><b>Resume</b>&nbsp;&nbsp;&nbsp;<v-icon>mdi-playlist-edit</v-icon></v-btn>
                </v-col>
                <v-col class="pb-0" v-else>
                    <b>Resume</b>                   
                </v-col>                
            </v-row>
            <v-row>
                <v-col class="pt-2 pb-4 pl-8">
                    <div v-if="hasResume">
                        <a @click="download(profile)">{{getFilename(profile._attachments)}}</a>
                    </div>
                </v-col>
            </v-row>                           
            <v-row>
                <v-col class="pb-0" v-if="!readOnly">
                    <v-btn text @click="editResumeAndSkills"><b>Skills</b>&nbsp;&nbsp;&nbsp;<v-icon>mdi-playlist-edit</v-icon></v-btn>
                </v-col>
                <v-col class="pb-0" v-else>
                    <b>Skills</b>                   
                </v-col>                
            </v-row>
            <v-row>
                <v-col class="pt-2 pb-4 pl-8">
                    <v-chip small class="ma-1" v-for="(skill,i) in profile.skills" :key="i">{{skill}}</v-chip>
                </v-col>
            </v-row>                           
        </v-container>        
    </v-container>
</template>

<script>
import personAvatar from '../person/person.avatar.vue';

import auth from "../../mixins/auth";

export default {
    mixins: [ auth ],
    props: {
        readOnly: {
            
        }
    },
    data: function () {
        return {
            profile: {},
            loading: false,
            hasResume: false
        }
    },
    mounted: async function () {
        this.loadProfile();
        console.log(this.$user);
    },
    created: function () {
    },
    computed: {
    },
    watch: {
    },
    methods: {
        async loadProfile() {
            this.loading = true;
            var result = await this.$api.get("profile/getMine", this.$user);
            if (result.data) {
                this.profile = result.data;
                this.new = false;

                if (this.profile._attachments && Object.keys(this.profile._attachments).length > 0) {
                    this.hasResume = true;
                }
            }
            this.loading = false;
        },
        async download(profile) {
            var result = await this.$api.get("profile/downloadFile?profileId=" + profile._id, this.$user);
            var record = result.data;

            if (record) {
                var filename = Object.keys(record)[0];
                var details = record[filename];
                var source = "data:" + details.content_type + ";base64," + details.data;
                var anchor = document.createElement("a");
                
                anchor.href = source;
                anchor.download = filename;
                anchor.click();
            }
        },
        getFilename(attachment) {
            if (!attachment) {
                return "None";
            }
            return Object.keys(attachment)[0];
        },
        editDetails() {
            this.$router.push({ name: "profile-personal-details" });
        },
        editAbout() {
            this.$router.push({ name: "profile-about-me" });
        },
        editJobPreferences() {
            this.$router.push({ name: "profile-job-preferences" });
        }, 
        editResumeAndSkills() {
            this.$router.push({ name: "profile-resume" });
        },
    },
    components: {
        personAvatar
    }
}
</script>