import Vue from "vue";
import { vuetify } from "@/plugins";

import "./mixins/mixins";
import "./styles/colours.css";

import router from "./routing/router";
import app from "./app/app.vue";

import "./styles/easymde.min.css"

var main = new Vue({
    data: {
        authToken: "",
        user: {},
        role: {}
    },
    router,
    vuetify,
    render: h => h(app)
});

main.$mount("#app");

export default main;
