<style>
</style>

<template>
    <v-container class="elevation-4" fill-height style="align-items:flex-start;position:relative;">
        <v-container>
            <v-col>
                <v-row>
                    <h2>My Open Company Jobs</h2>
                </v-row>
                <v-row>
                    <v-data-table height="70vh" style="width:100%;" :calculate-widths="true" :headers="headers" :loading="loading" :items="items" class="elevation-2 mt-4" :single-expand="true" :expanded.sync="expanded">
                        <template v-slot:item.title="{ item }">
                            <div class="mt-2" style="font-weight:bold;">{{ item.title }}</div>
                            {{ item.summary }}<br/>                            
                            <v-btn @click="viewApplicants(item)" small class="mt-1 mb-2">View Applicants ({{ getApplicantCount(item) }})</v-btn>
                        </template>
                        <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length">
                                More info about {{ item.name }}
                            </td>
                        </template>                        
                        <template v-slot:item.actions="{ item }">
                            <v-btn @click="editJob(item)" small width="90" class="mt-1" color="primary">Edit</v-btn><br/>
                            <v-btn @click="deleteJob(item)" small  width="90" class="mt-1 mb-1" color="secondary">Delete</v-btn>
                        </template>
                        <template v-slot:item.postDateTicks="{ item }">
                            {{ item.postDateDisplay }}
                        </template>
                        <template v-slot:item.closeDateTicks="{ item }">
                            {{ item.closeDateDisplay }}
                        </template>
                        <template v-slot:top>
                            <v-text-field filled v-model="search" label="Search Jobs" class="mx-4 mt-4" hide-details clearable>
                                <template v-slot:append-outer>
                                    <v-btn @click="find()">Search Jobs</v-btn>
                                </template>
                            </v-text-field>
                            <div class="mt-2 ml-4">
                                <v-container class="d-inline-flex">
                                    <v-row>
                                        <v-col cols="4" class="pt-0 pb-0">
                                            <v-checkbox v-model="filterOptions.closed" label="Include Closed" hide-details class="mt-0"></v-checkbox>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </div>
                            <v-btn :disabled="loading" small class="mt-2 ml-4" @click="addJob" color="primary">Add New Job</v-btn>
                        </template>                        
                    </v-data-table>
                </v-row>
            </v-col>
        </v-container>
    </v-container>
</template>

<script>
import events from "../../app/app.events";

import auth from "../../mixins/auth";

export default {
    mixins: [ auth ],
    props: {
        id: {}
    },
    data: function () {
        return {
            loading: false,
            headers: [
                { text: "Job", value: "title" },
                { text: "Posted", value: "postDateTicks" },
                { text: "Closing", value: "closeDateTicks" },
                { text: "Actions", value: "actions", width: "120" }
            ],
            items: [],
            filterOptions: {
                closed: false,
            },            
            search: "",
            expanded: []
        }
    },
    mounted: async function () {
        var savedSearch = localStorage.getItem("savedJobListSearch");
        if (savedSearch) {
            savedSearch = JSON.parse(savedSearch);
            if (savedSearch.searchText) {
                this.search = savedSearch.searchText;
            }

            if (savedSearch.filterOptions) {
                this.filterOptions = savedSearch.filterOptions;
            }


            this.find();
        }

        //await this.loadJobs();

    },
    created: function () {
    },
    computed: {
    },
    methods: {
        async find() {
            var q = this.search;
            if (!q || q.length == 0) {
                q = "*";
            }
            else {
                q = this.search.includes(":") || this.search.includes("*") ? this.search : "*" + this.search + "*";
            }

            /*if (filters.length > 0) {
                q = q + " AND status:(" + filter +")";
            }*/

            if (this.filterOptions.closed) {
                q = q + "&closed=true";
            }

            await this.loadJobs(q);
        },           
        addJob() {
            this.$router.push({ name: "job-posting" });
        },
        editJob(item) {
            console.log(item);
            this.$router.push({ name: "job-posting", params: { id: item.id } });
        },
        getApplicantCount(item) {
            if (!item.applicants) {
                return 0;
            }
            var applicants = Object.keys(item.applicants);
            return applicants.length;
        },
        async deleteJob(item) {

            var waiting = new Promise((resolve) => {
                this.$bus.emit(events.APP_DIALOG_OPEN, { title: "Confirm Job Delete", message: "Are you sure you want to delete this job?", onConfirm: () => { resolve(true) }, onCancel: () => { resolve(false) } });   
            });

            var okay = await waiting;
            if (okay) {
                await this.$api.delete("job/delete?id=" + item.id, this.$user);
                setTimeout(() => {
                    this.loadJobs("*");
                }, 500);
            }
        },
        viewApplicants(item) {
            this.$router.push({ name: "job-applicants", params: { id: item.id, item: item } });

        },
        async loadJobs(q) {
            this.loading = true;

            var savedSearch = {
                searchText: this.search,
                filterOptions: this.filterOptions
            };
            
            localStorage.setItem("savedJobListSearch", JSON.stringify(savedSearch));

            console.log(savedSearch);

            var result = await this.$api.get("job/list?q=" + q, this.$user);
            if (result) {
                var items = [];
                for (var record of result.data) {
                    var job = record._source;
                    job.id = record._id;
                    items.push(job);
                }               
                this.$set(this, "items", items);
            }
            this.loading = false;
        }
    },
    components: {
    }
}
</script>