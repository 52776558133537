<style scoped>
</style>

<template>
    <v-dialog persistent v-model="show" scrollable max-width="300px">
        <v-card>
            <v-card-text class="pa-4">
                <div class="d-flex" style="flex-direction:column;justify-content:center;align-items: center;">
                    <div style="text-align:center;"><strong>Checking Login Information</strong><br/><br/></div>
                    <app-loader :state="state"></app-loader><br/>
                    <div v-if="state == 'success'" style="text-align:center;">Login was successful, please return to the login webpage to continue to use <strong>{{ appName }}</strong><br/><br/>You may now close this window</div>
                    <div v-else-if="state == 'failure'" style="text-align:center;">Your login was unsuccessful. This may be due to an expired login link or current service issues. Please try to send a new link via the login screen.<br/><br/>You may now close this window</div>
                </div>                    
            </v-card-text>
        </v-card>
    </v-dialog>        
</template>

<script>
import config from "../../app/app.config";
import appLoader from "../../app/components/app.loader.vue";

import auth from "../../mixins/auth";

export default {
    mixins: [ auth ], 
    data: function () {
        return {
            state: "loading",
            show: true,
            appName: config.app.name
        }
    },
    mounted: function () {
        //console.log(this.$route.query.code);
        this.checkToken();
    },
    created: function () {
    },
    computed: {
    },
    methods: {
        async checkToken() {
            this.state = "loading";

            var results = await this.$api.post("login/authorise", {
                code: this.$route.query.code
            });

            if (results.data.successful) {
                this.state = "success";

                this.$root.authToken = results.data.payload.token;
                localStorage.setItem("authToken", this.$root.authToken);
                this.show = false;
                this.$router.replace({ name: "home" });
            }
            else {
                this.state = "failure";
            }
        }
    },
    components: {
        appLoader
    }
}
</script>