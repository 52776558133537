<style lang="scss">
    @import "~vuetify/src/styles/styles.sass";

    .app-header {
        background-color: white !important;
        z-index:10 !important;
    }

    .app-logo {
        margin-top: 6px;
    }
</style>

<template>
    <v-app-bar app tile :height="height" style="overflow:hidden;" elevation="2" class="app-header">
        <router-link to="/" class="logo-container">
            <img src="../../images/so-logo.png" class="app-logo" height="40" />
        </router-link>
        <v-spacer></v-spacer>
        <v-toolbar-title style="color:white;">{{ $route.meta ? $route.meta.title : appName }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon class="ma-0 pa-0 d-flex d-md-none" @click="openDrawer">
            <v-icon color="black">mdi-menu</v-icon>
        </v-btn>
        <v-toolbar-items class="d-none d-md-flex">
            <template v-for="item in menu.items">
                <v-menu v-if="item.children" :key="item.name + '-parent'" open-on-hover bottom min-width="240" offset-y transition="scroll-y-reverse-transition">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn text class="menu-button" v-bind="attrs" v-on="on" :to="{ name: item.name }">{{item.meta.title}}</v-btn>
                    </template>

                    <v-list>
                        <v-list-item v-for="(item, index) in item.children" :key="index" :to="{ name: item.name }">
                            <v-list-item-title>{{ item.meta.title }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <v-btn text v-else :key="item.name" class="menu-button" :to="{ name: item.name }">{{item.meta.title}}</v-btn>
            </template>
        </v-toolbar-items>
    </v-app-bar>
</template>

<script>
import tools from "../../util/tools.utils";

import config from "../app.config";
import events from "../app.events";

export default { 
    data: function () {
        return {
            height: config.header.height + "px",
            appName: config.app.name,
            menu: {
                items: [
                ]
            }             
        }
    },
    mounted: async function () {
        await this.$root.waiting;
        this.menu.items = this.getRoutes("app");
    },
    created: function () {
    },
    computed: {
    },
    methods: {
        getRoutes(parentName) {
            var routes = this.$router.resolveByNameWithChildren(parentName);

            var filteredRoutes = tools.deepCopy(routes.children);
            filteredRoutes = this.filterRoutes(filteredRoutes);

            for (var route of filteredRoutes) {
                if (route.children) {
                    route.children = this.filterRoutes(route.children);
                }
            }

            filteredRoutes = filteredRoutes.filter(r => !r.children || r.children.length > 0);

            return filteredRoutes;
        },
        filterRoutes(routes) {
            var filteredRoutes = routes.filter(
                (r) => {
                    if (r.meta.hide) {
                        return false;
                    }

                    if (!r.meta.auth) {
                        return true;
                    }

                    for (var action of r.meta.auth) {
                        if (!this.$role.actions.includes(action)) {
                            return false;
                        }
                    }

                    return true;
                }
            );

            return filteredRoutes;
        },        
        openDrawer() {
            this.$bus.emit(events.APP_DRAWER_OPEN, { show: true });
        },
        doNothing() {}
    },
    components: {
    }
}
</script>