<style>
    .custom-heading {
        color: rgba(0,0,0,0.6);
        background-color: #F0F0F0;
    }

    #descriptionEditor .ql-editor { min-height:60vh !important }
</style>

<template>
    <v-container class="elevation-4" fill-height style="align-items:flex-start;position:relative;">
        <v-overlay :value="loading">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-container class="pb-14">
            <v-row>
                <v-col class="pb-0">
                    <h2>About Me</h2>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="pt-4 pb-0">
                    <vue-editor ref="descriptionEditor" id="descriptionEditor" v-model="profile.about" :editor-toolbar="toolbar"></vue-editor>
                    <div class="v-text-field__details pa-2 pl-3">
                        <div class="v-messages theme--light">
                            <div class="v-messages__wrapper">
                                <div class="v-messages__message">Tell us a bit about yourself.</div>
                            </div>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-container>        
        <div style="position:fixed;bottom:0px;left:0px;width:100%;">
            <v-container class="pa-0">
                <v-card class="pt-4 pb-2 elevation-6" style="border-radius:0px;">
                    <v-card-actions class="pt-0 d-flex justify-center">
                        <v-btn color="primary" @click="save" width="150" :loading="saving">
                            Save
                        </v-btn>
                        <v-btn color="secondary" @click="cancel" width="150" :disabled="saving">
                            Cancel
                        </v-btn>                        
                    </v-card-actions>
                </v-card>
            </v-container>
        </div>
    </v-container>
</template>

<script>
import { VueEditor } from "vue2-editor";

import events from "../../app/app.events";

import QuillMarkdown from "quilljs-markdown";

import auth from "../../mixins/auth";

export default {
    mixins: [ auth ],
    props: {
        id: {}
    },
    data: function () {
        return {
            profile: {},
            loading: false,
            saving: false,
            toolbar: [
                ['bold', 'italic', 'underline', 'strike'],        // toggled buttons

                [{ 'header': [1, 2, 3, false] }],

                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent

                ['clean']  
            ]            
        }
    },
    mounted: async function () {
        this.loadProfile();
        var quill = this.$refs.descriptionEditor.quill;
        //console.log(quill);
        const quillMarkdown = new QuillMarkdown(quill, {});
        console.log(quillMarkdown);
    },
    created: function () {
    },
    computed: {
    },
    watch: {
        "profile.about"(val) {
            var about = this.stripHtml(val);
            this.profile.aboutText = about;
        }
    },
    methods: {
        async save() {
            this.saving = true;
            await this.$api.post("profile/upsert", this.profile, this.$user);
            this.saving = false;

            this.$bus.emit(events.APP_SNACKBAR_MESSAGE, "Profile Saved");
            setTimeout(() => {
                this.$router.push({ name: "profile-overview" });
            }, 1000);
        },
        cancel() {
            this.$router.push({ name: "profile-overview" });
        },
        async loadProfile() {
            this.loading = true;
            var result = await this.$api.get("profile/getMine", this.$user);
            if (result.data) {
                this.profile = result.data;
                this.new = false;
            }
            this.loading = false;
        },
        stripHtml(html) {
            var clean = html.replace(/<[^>]+>/gm, ' ');
            return clean;
        },
    },
    components: {
        VueEditor
    }
}
</script>