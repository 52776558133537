import axios from "axios";

var instance = axios.create();

instance.interceptors.response.use(undefined, function (error) {
    return Promise.reject(error);
});

export default {
    async get(base, component, path, user, throwError) {
        return this.execute(base, instance.get, component, path, null, user, throwError);
    },
    async delete(base, component, path, user, throwError) {
        return this.execute(base, instance.delete, component, path, null, user, throwError);
    },
    async post(base, component, path, payload, user, throwError, isForm) {
        return this.execute(base, instance.post, component, path, payload, user, throwError, isForm);
    },
    async execute(base, method, component, path, payload, user, throwError, isForm) {
        var result = null;

        var methodParams = [base + path];

        if (payload) {
            methodParams.push(payload);
        }

        var requestConfig = {
            headers: {}
        }

        if (user) {        
            requestConfig.headers["Authorization"] = "Bearer " + user.token;
        }

        if (isForm) {
            requestConfig.headers["Content-Type"] = "multipart/form-data";
        }

        if (user || isForm) {
            methodParams.push(requestConfig);
        }

        result = await method(...methodParams).catch(function(error) { 
            result = null;
            if (throwError) {
                throw error;
            }
        });

        return result;
    }
}