import { format, intlFormat, parseISO } from "date-fns";

export default {
    formatDate(date) {
        return intlFormat(new Date(date), { weekday: "long", year: "numeric", month: "long", day: "numeric", hour: "numeric", minute: "numeric" });
    },
    formatDateShort(date) {
        return format(new Date(date), "dd/MM/yyyy");
    },
    formatDateISO(date) {
        return format(new Date(date), "yyyy-MM-dd");
    },    
    parseISO(str) {
        return parseISO(str);
    }
}