<style>
    .bottom-sheet {
        position: fixed;
        bottom: 0px;
        max-height: initial !important;
    }
</style>

<template>
    <v-bottom-sheet v-model="isOpen" content-class="bottom-sheet" attach=".v-application--wrap" scrollable max-width="800px">
        <v-card style="border-bottom-right-radius:0px;border-bottom-left-radius:0px;">
            <v-card-title>
                <v-btn small depressed color="secondary" @click="close" style="width:64px;">Cancel</v-btn>
                <v-spacer></v-spacer>
                {{ title }}
                <v-spacer></v-spacer>
                <v-btn small depressed :color="button.color ? button.color : 'default'" style="width:64px;max-width:64px;" :style="{visibility: button.show ? 'visible' : 'hidden'}" @click="buttonAction">{{ button.title }}</v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text style="height:75vh;display:flex;justify-content:center;" :style="{ alignItems: center ? 'center' : 'initial'}" class="pl-0 pr-0 pb-0">
                <component :is="componentFile" v-bind="config.props" v-on="config.listeners" ref="component"></component>
            </v-card-text>
        </v-card>
    </v-bottom-sheet>
</template>

<script>
import events from "../app.events";

export default {
    props: {
        center: {
            default: false
        }
    },     
    data: function () {
        return {
            isOpen: false,
            title: "",
            button: {
                title: "",
                action: null,
                show: false
            },
            config: {
                file: "app/components/app.empty",
                props: {},
                listeners: {}
            },
            componentFile: null
        }
    },
	mounted() {
		if (this.config) {
			this.$set(this, "componentFile", () => import("../../" + this.config.file));
		}
	},	
	watch: {
		config(val) {
			this.$set(this, "componentFile", () => import("../../" + val.file));
		}
	},
    created: function () {
        this.$bus.handle(events.APP_BOTTOM_SHEET_OPEN, this.open.bind(this));        
        this.$bus.handle(events.APP_BOTTOM_SHEET_TOGGLE, this.toggle.bind(this));        
    },
    computed: {
    },
    methods: {
        open({ show, config, button, title }) {
            this.isOpen = show;
            this.title = title;
            if (config) {
                this.config = config;
            }
            if (button) {
                this.button = button;
            }
        },
        toggle() {
            this.isOpen = !this.isOpen;
        },
        close() {
            this.isOpen = false;
        },
        buttonAction() {
            if (this.button.action) {
                var result;
                if (this.$refs.component.getResult) {
                    result = this.$refs.component.getResult();
                }
                this.button.action(result);
            }
        }
    },
    components: {
    }
}
</script>