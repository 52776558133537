<style scoped>
</style>

<template>
    <v-container class="elevation-4" fill-height style="align-items:flex-start;position:relative;">
        <v-overlay :value="loading">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-container class="pb-14">
            <job-posting-details v-if="job" :item="job"></job-posting-details>
        </v-container>
        <div style="position:fixed;bottom:0px;left:0px;width:100%;">
            <v-container class="pb-0">
                <v-card class="pt-2 elevation-6">
                    <v-card-actions class="pt-0 d-flex justify-center">
                        <v-btn color="primary" @click="apply" width="150" :loading="applying" :disabled="(job && job.applicants && !!job.applicants[$user.id])">
                            Apply <v-icon class="ml-2" v-if="(job && job.applicants && !!job.applicants[$user.id])">mdi-checkbox-marked-circle</v-icon>
                        </v-btn>
                        <v-btn color="secondary" @click="cancel" width="150" :disabled="applying">
                            Cancel
                        </v-btn>                        
                    </v-card-actions>
                </v-card>
            </v-container>
        </div>
    </v-container>
</template>

<script>
import jobPostingDetails from './job.posting.details.vue';

import events from "../../app/app.events";

import auth from "../../mixins/auth";

export default {
    mixins: [ auth ],
    props: {
        id: {},
        item: {}
    },
    data: function () {
        return {
            applying: false,
            loading: false,
            posting: null
        }
    },
    async mounted() {
        if (this.id && !this.item) {
            await this.loadPosting(this.id);
        }
    },
    created: function () {
    },
    computed: {
        job() {
            if (this.item) {
                return this.item; 
            }

            return this.posting;
        }
    },
    methods: {
        async loadPosting(id) {
            this.loading = true;
            var result = await this.$api.get("job/get?id=" + id, this.$user);
            var posting = result.data;
            if (!posting) {
                this.$bus.emit(events.APP_SNACKBAR_MESSAGE, "Job Posting Not Found");
                return;
            }

            this.$set(this, "posting", posting);

            console.log(this.posting);
            this.loading = false;
        },        
        async apply() {
            this.applying = true;
            var waiting = new Promise((resolve) => {
                this.$bus.emit(events.APP_DIALOG_OPEN, { title: "Confirm application", message: "Do you wish to apply for " + this.item.title + "?<br/>Your application will be sent to your allocated pathways officer for review and support.", onConfirm: () => { resolve(true) }, onCancel: () => { resolve(false) } });   
            });

            var okay = await waiting;
            if (okay) {
                await this.$api.post("job/apply", { jobId: this.item.id }, this.$user);

                this.$bus.emit(events.APP_SNACKBAR_MESSAGE, "Job Application Submitted for Review");

                setTimeout(() => {
                    this.$router.go(-1);
                }, 500);                
            }
            this.applying = false;
        },
        cancel() {
            this.$router.go(-1);
        }
    },
    components: {
        jobPostingDetails
    }
}
</script>