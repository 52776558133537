<style>
    .profile-sidebar {
        position: absolute;
        z-index: 1;
        right: 103%;
        top: 64px;
        width: 190px;
    }

    .markdown-render ul {
        margin-bottom: 16px;
    }    
</style>

<template>
    <v-container class="elevation-4" fill-height style="align-items:flex-start;position:relative;">
        <v-overlay :value="loading">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-container class="pb-14">
            <v-row>
                <v-col class="pb-0">
                    <h2>View Profile</h2>
                </v-col>
            </v-row>
            <v-row v-if="profile.officer">
                <v-col class="pb-0">
                    <h4>Assigned Pathways Officer: {{ profile.officer.name }}</h4>                    
                </v-col>
            </v-row>
            <v-row>
                <v-col class="pb-0">
                    <b>Personal Details</b>                   
                </v-col>                
            </v-row>
            <v-row>
                <v-col class="pt-2 pb-0 pl-8">
                    <person-avatar size="36" :avatar="profile.avatar" :name="profile.firstName + ' ' + profile.lastName"></person-avatar>
                    <div style="display:inline-block;vertical-align:middle;" class="ml-2">{{ profile.firstName }} {{ profile.lastName }} ({{ profile.email }})</div>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="pb-0">
                    <b>About Me</b>                   
                </v-col>                
            </v-row>
            <v-row>
                <v-col class="pt-2 pb-0 pl-8">
                    <div v-html="profile.about" class="markdown-render"></div>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="pb-0">
                    <b>Job Preferences</b>                   
                </v-col>                
            </v-row>
            <v-row>
                <v-col class="pt-2 pb-0 pl-8" v-if="profile.preferred">
                    Preferred Categories
                </v-col>
            </v-row>
            <v-row>
                <v-col class="pt-2 pb-0 pl-8" v-if="profile.preferred">
                    <v-chip small class="ma-1" v-for="(category, i) in profile.preferred.categories" :key="i">{{category}}</v-chip>
                </v-col>
            </v-row>            
            <v-row>
                <v-col class="pt-2 pb-0 pl-8" v-if="profile.preferred">
                    Preferred Types
                </v-col>
            </v-row>
            <v-row>
                <v-col class="pt-2 pb-0 pl-8" v-if="profile.preferred">
                    <v-chip small class="ma-1" v-for="(type, i) in profile.preferred.types" :key="i">{{type}}</v-chip>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="pt-2 pb-0 pl-8" v-if="profile.preferred">
                    Within {{ profile.preferred.within }}kms of {{ profile.preferred.location?.display }}
                </v-col>
            </v-row>
            <v-row>
                <v-col class="pb-0">
                    <b>Resume</b>                   
                </v-col>                
            </v-row>
            <v-row>
                <v-col class="pt-2 pb-4 pl-8">
                    <div v-if="hasResume">
                        <a @click="download(profile)">{{getFilename(profile._attachments)}}</a>
                    </div>
                </v-col>
            </v-row>                           
            <v-row>
                <v-col class="pb-0">
                    <b>Skills</b>                   
                </v-col>                
            </v-row>
            <v-row>
                <v-col class="pt-2 pb-4 pl-8">
                    <v-chip small class="ma-1" v-for="(skill,i) in profile.skills" :key="i">{{skill}}</v-chip>
                </v-col>
            </v-row>                           
        </v-container>
        <div style="position:fixed;bottom:0px;left:0px;width:100%;z-index:10;">
            <v-container class="pa-0">
                <v-card class="pt-4 pb-2 elevation-6">
                    <v-card-actions class="pt-0 d-flex justify-center">
                        <v-btn color="secondary" width="150" @click="close">
                            Close
                        </v-btn>                        
                    </v-card-actions>
                </v-card>
            </v-container>
        </div>
    </v-container>
</template>

<script>
import events from "../../app/app.events";
import personAvatar from '../person/person.avatar.vue';

import auth from "../../mixins/auth";

export default {
    mixins: [ auth ],
    props: {
        id: {}
    },
    data: function () {
        return {
            profile: {},
            loading: false,
            hasResume: false
        }
    },
    mounted: async function () {
        console.log(this.id);
        this.loadProfile();
    },
    created: function () {
    },
    computed: {
    },
    watch: {
    },
    methods: {
        async loadProfile() {
            if (!this.id) {
                this.$bus.emit(events.APP_SNACKBAR_MESSAGE, "Profile not found");
                return;
            }
            this.loading = true;
            var result = await this.$api.get("profile/getByUserId?userId=" + this.id, this.$user);

            if (result.data) {
                this.profile = result.data;
                this.new = false;

                if (this.profile._attachments && Object.keys(this.profile._attachments).length > 0) {
                    this.hasResume = true;
                }
            }

            this.loading = false;
        },
        async download(profile) {
            var result = await this.$api.get("profile/downloadFile?profileId=" + profile._id, this.$user);
            var record = result.data;

            if (record) {
                var filename = Object.keys(record)[0];
                var details = record[filename];
                var source = "data:" + details.content_type + ";base64," + details.data;
                var anchor = document.createElement("a");
                
                anchor.href = source;
                anchor.download = filename;
                anchor.click();
            }
        },
        getFilename(attachment) {
            if (!attachment) {
                return "None";
            }
            return Object.keys(attachment)[0];
        },
        close() {
            this.$router.go(-1);
        }
    },
    components: {
        personAvatar
    }
}
</script>