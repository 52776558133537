<style lang="scss">
.container {
    max-width: 850px !important;
    align-items: flex-start !important;
    position: relative !important;
}

.v-btn {
    border-radius:0px !important;
    text-transform:unset !important;
 } 

 $font-family:'Open Sans';

 .v-application {
    [class*='text-'] {
        font-family: $font-family, sans-serif !important;
    }
    font-family: $font-family, sans-serif !important;
}

</style>

<template>
    <router-view></router-view>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import tools from "../util/tools.utils";

import "./app.cache";
import "./app.security";

export default { 
    data: function () {
        return {
        }
    },
    mounted: async function () {
    },
    created: function () {
        this.loadDeviceId();
        this.loadAuthToken();
        this.setUserByToken(this.$root.authToken);
    },
    computed: {
        token() {
            return this.$root.authToken;
        }
    },
    watch: {
        token(val) {
            this.setUserByToken(val);
        }
    },
    methods: {
        loadDeviceId() {
            var storedDeviceId = localStorage.getItem("deviceId");
            if (storedDeviceId) {
                this.$root.deviceId = storedDeviceId;
            }
            else {
                this.$root.deviceId = uuidv4();
                localStorage.setItem("deviceId", this.deviceId);
            }
        },
        loadAuthToken() {
            var authToken = localStorage.getItem("authToken");
            if (authToken && authToken != "null" && authToken != "undefined") {
                this.$root.authToken = authToken;
            }
        },
        setUserByToken(val) {
            var payload = tools.jwtDecode(val);
            if (payload) {
                var user = payload.user;
                user.token = val;
                this.$root.user = user;
                this.$root.waiting = this.loadRole();
            }
        },
        async loadRole() {
            // load role from database
            var result = await this.$api.get("role/getMine", this.$user);
            this.$root.role = result.data;
        }
    },
    components: {
    }
}
</script>
